import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-select-item',
  templateUrl: './select-item.component.html',
  styleUrls: ['./select-item.component.scss'],
})
export class SelectItemComponent implements OnInit {

  @Input()
  public display: string = '';

  @Input()
  public value: string = '';

  @Input()
  public selected: boolean = false;

  @Output()
  public toggleSelect: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  toggleItem = () => {
    this.selected = !this.selected;
    this.toggleSelect.emit(this.value);
  }

}

import { Component, OnInit } from '@angular/core';
import { IonNav, ModalController, NavParams } from '@ionic/angular';
import { AddScheduleComponent } from 'src/app/components/add-schedule/add-schedule.component';
import { TeamService } from 'src/app/services/team.service';
import { CreateTeamInvitePage } from '../create-team-invite/create-team-invite.page';
import { TeamCreationResult } from '../type';
import _ from 'lodash';


@Component({
  selector: 'app-create-team-schedule',
  templateUrl: './create-team-schedule.page.html',
  styleUrls: ['./create-team-schedule.page.scss'],
})
export class CreateTeamSchedulePage implements OnInit {

  public teamId: number = null;
  public teamName: string = '';
  public teamData: TeamCreationResult;
  public schedule: Array<any> = [];
  
  
  public subscribe = null;

  constructor(
    private nav: IonNav,
    private navParams: NavParams,
    private modalCtrl: ModalController,
    private teamService: TeamService
  ) { 
    this.teamData = this.navParams.get('prevData')
  }
 
  ngOnInit() {
  }

  ionViewWillEnter() {
    this.subscribe = this.teamService.teamsState.subscribe(state => {
      if (state) {
        this.teamService.getTeamData(this.teamData.pk, {p: 'home'}, true).then(result => {
          console.log("New Schedule after game add === ", result);
          let schedules = _.orderBy(result.full_schedule, ['timestamp'], ['asc']);
          this.schedule = schedules
          .map(sch => {
            const dateObj = new Date(sch.timestamp * 1000);
            const month = dateObj.getMonth() + 1;
            const date = dateObj.getDate();
            const hour = dateObj.getHours() > 12 ? dateObj.getHours() - 12 : dateObj.getHours();
            const minute = dateObj.getMinutes() > 9 ? dateObj.getMinutes() : '0' + dateObj.getMinutes();
            const ampm = dateObj.getHours() > 12 ? 'PM': 'AM';
            return {
              opponent: sch.opponent_name,
              date: month + '/' + date,
              time: hour + ':' + minute + ' ' + ampm
            }
          });
        })
      }
    })
  }

  ionViewDidLeave() {
    // if (this.subscribe) this.subscribe();
  }

  addGame = () => {
    this.modalCtrl.create({
      component: AddScheduleComponent,
      cssClass: 'second-add-modal',
      componentProps: {
        'teamId': this.teamData.pk
      }
    }).then(popOver => {
      popOver.present();
    })
  }

  goNext = () => {
    this.nav.push(CreateTeamInvitePage, {prevData: this.teamData})
  }

  clickSkip = () => {
    this.modalCtrl.dismiss();
  }

}

import { Component, OnInit } from '@angular/core';
import { IonNav, NavParams } from '@ionic/angular';
import { BaseService } from 'src/app/services/base.service';
import { CreateTeamTypePage } from '../create-team-type/create-team-type.page';
import { CreateTeamLocationPage } from '../create-team-location/create-team-location.page';
import { CreateTeamData } from '../type';

@Component({
  selector: 'app-create-team-role',
  templateUrl: './create-team-role.page.html',
  styleUrls: ['./create-team-role.page.scss'],
})
export class CreateTeamRolePage implements OnInit {

  public teamData: CreateTeamData = {}

  public displayData = [
    {
      name: 'Coaching Staff',
      value: 0
    },
    {
      name: 'Team Manager',
      value: 1
    },
    // {
    //   name: 'Fan / Family',
    //   value: 2
    // },
    // {
    //   name: 'Player',
    //   value: 3
    // },
    // {
    //   name: 'Other',
    //   value: 4
    // }
  ]
  public selectedRole: number = -1;

  constructor(
    private nav: IonNav,
    private navParams: NavParams,
    private baseService: BaseService
  ) { }

  ngOnInit() {
    console.log("Navigation Params === ", this.navParams.get('prevData'))
    this.teamData = this.navParams.get('prevData')
  }

  clickNext = () => {
    if (this.selectedRole === -1) {
      this.baseService.showAlertMessage('Error!', 'Please choose the team role!');
      return;
    }
    this.teamData.team_role = this.selectedRole;
    this.nav.push(CreateTeamLocationPage, {prevData: this.teamData})
  }

  clickOption = (role) => {
    this.selectedRole = role;
    this.teamData.team_role = this.selectedRole;
  }

}

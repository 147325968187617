import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeCheckPipe } from './time-check.pipe';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [TimeCheckPipe],
  exports: [TimeCheckPipe]
})

export class TimeCheckModule {

}

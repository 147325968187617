import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SideMenuComponent } from './side-menu.component';
import { AddTeamComponentModule } from '../add-team/add-team.module';
import { AddPlayerComponentModule } from '../add-player/add-player.module';
import { TagComponentComponentModule } from '../tag-component/tag-component.module';
import { ManageModalComponentModule } from '../manage-modal/manage-modal.module';
import { ManageHomePageModule } from '../../pages/manage/manage-home/manage-home.module';
import { UpgradeAccountComponentModule } from '../upgrade-account/upgrade-account.module';

@NgModule({
  imports: [ CommonModule, FormsModule, IonicModule, 
    AddTeamComponentModule, AddPlayerComponentModule, TagComponentComponentModule,
    ManageModalComponentModule,
    ManageHomePageModule,
    UpgradeAccountComponentModule ],
  declarations: [SideMenuComponent],
  exports: [SideMenuComponent]
})
export class SideMenuModule {}

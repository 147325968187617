import { Injectable, EventEmitter } from '@angular/core';
import { filter } from 'rxjs/operators';

interface GlobalEvent {
  name: string;
  data: any;
}

export enum GlobalEvents {
  userProfiledLoaded = 'profile-loaded', // fired when user profile is fulled loaded
  liveStreamMoved = 'livestream-moved',
  domainLoaded = 'domain-loaded'
}

@Injectable({
  providedIn: 'root'
})
export class GlobalEmitterService {

  public emitter: EventEmitter<GlobalEvent> = new EventEmitter<GlobalEvent>();

  constructor() { }

  public emit(name: string, data?: any) {
    this.emitter.emit({
      name, data
    });
  }

  public listen(eventName: string) {
    return this.emitter.pipe(filter(event => event.name === eventName));
  }
}

import { Component, OnInit } from '@angular/core';
import { IonNav, ModalController, NavParams } from '@ionic/angular';
import { AddPlayerComponent } from 'src/app/components/add-player/add-player.component';
import { BaseService } from 'src/app/services/base.service';
import { CreateTeamRosterPage } from '../create-team-roster/create-team-roster.page';
import { TeamCreationResult } from '../type';

@Component({
  selector: 'app-create-team-finish',
  templateUrl: './create-team-finish.page.html',
  styleUrls: ['./create-team-finish.page.scss'],
})
export class CreateTeamFinishPage implements OnInit {

  public teamId: number = null;
  public teamName: string = '';
  public teamData: TeamCreationResult = null;

  constructor(
    private nav: IonNav,
    private navParams: NavParams,
    private baseService: BaseService,
    private modalCtrl: ModalController
  ) {
    this.teamData = this.navParams.get('prevData')
    console.log("Team DAta ==== ", this.teamData);
   }

  ngOnInit() {
  }

  clickSkip = () => {
    this.modalCtrl.dismiss()
  }

  addPlayer = () => {
    // this.modalCtrl.create({
    //   component: AddPlayerComponent,
    //   cssClass: 'second-add-modal',
    //   componentProps: {
    //     'teamId': this.teamId
    //   }
    // }).then(popOver => {
    //   popOver.present();
    // })

    this.nav.push(CreateTeamRosterPage, {prevData: this.teamData})
  }

}

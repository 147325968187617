import { Component, OnInit, Input } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { InAppPurchase } from '@ionic-native/in-app-purchase/ngx';
import { ModalController, Platform } from '@ionic/angular';
import { BaseService } from 'src/app/services/base.service';
import { TeamService } from 'src/app/services/team.service';
import { Storage } from '@ionic/storage';
import { ProfileData, UserService } from 'src/app/services/user.service';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';

@Component({
  selector: 'app-upgrade-account',
  templateUrl: './upgrade-account.component.html',
  styleUrls: ['./upgrade-account.component.scss'],
})
export class UpgradeAccountComponent implements OnInit {
  @Input()
  public navigationData: NavigationExtras = undefined
  @Input()
  public page: string = undefined

  public myProductId: string = 'com.pitchaware.primemonthly';
  public myLiveProductId: string = 'com.pitchaware.livemonthly';
  public myultraProductId: string = 'com.pitchaware.ultramonthly';
  public productList: Array<any> = [];
  public buttonHidden: boolean = false;
  public profile: ProfileData = null;
  public access: number = 0;
  public selectedOption: string = 'free'
  public ultra_available: boolean = false;

  constructor(
    private iapurchase: InAppPurchase,
    private platform: Platform,
    private modalCtrl: ModalController,
    private baseService: BaseService,
    private teamService: TeamService,
    private router: Router,
    private storage: Storage,
    private userService: UserService,
    private iab: InAppBrowser
  ) { }

  ngOnInit() {}

  ionViewWillEnter() {
    this.platform.ready().then(() => {
      this.storage.get('profile').then(result => {
        this.profile = JSON.parse(result);
        this.access = this.profile?.access?.level || 0
        if (this.profile.ultra_available == true) {
          this.ultra_available = true
        }
        
        if (this.access > 6) {
          this.selectedOption = 'live'
        } else if (this.access > 4) {
          this.selectedOption = 'prime'
        } else {
          this.selectedOption = 'free'
        }
      })
      // const producIds = [this.myProductId];
      // this.baseService.showLoading('Getting...')
      // this.iapurchase.getProducts(producIds).then((value) => {
      //   console.log("Get In app Purchase = ", JSON.stringify(value));
      //   value.forEach(item => {
      //     this.productList.push(item);
      //   })
      //   // loader.dismiss();
      // }).catch(error => {
      //   console.log("Get In app Purchase Error = ", JSON.stringify(error));
      // }).finally(() => {
      //   this.baseService.dismissLoading()
      // })
    });
  }

  dismiss(event) {
    event.stopPropagation();
    this.modalCtrl.dismiss();
  }

  preventDismiss(event) {
    event.stopPropagation();
  }

  openTerms = () => {
    this.iab.create("https://www.pitchaware.com/terms/", "_system")
    // window.open("https://www.pitchaware.com/terms/", "_system");
  }

  openPrivacy = () => {
    this.iab.create("https://www.pitchaware.com/privacy/", "_system");
  }

  getProducts = (option) => {
    if (option === 'prime') {
      this.productList = [];
      const producIds = [this.myProductId];
      this.baseService.showLoading('Getting...')
      this.iapurchase.getProducts(producIds).then((value) => {
        console.log("Get In app Purchase = ", JSON.stringify(value));
        value.forEach(item => {
          this.productList.push(item);
        })
        // loader.dismiss();
      }).catch(error => {
        console.log("Get In app Purchase Error = ", JSON.stringify(error));
      }).finally(() => {
        this.baseService.dismissLoading()
      })
    }
    if (option === 'live') {
      this.productList = [];
      const producIds = [this.myLiveProductId];
      this.baseService.showLoading('Getting...')
      this.iapurchase.getProducts(producIds).then((value) => {
        console.log("Get In app Purchase = ", JSON.stringify(value));
        value.forEach(item => {
          this.productList.push(item);
        })
        // loader.dismiss();
      }).catch(error => {
        console.log("Get In app Purchase Error = ", JSON.stringify(error));
      }).finally(() => {
        this.baseService.dismissLoading()
      })
    }
    if (option === 'ultra') {
      this.productList = [];
      const producIds = [this.myultraProductId];
      this.baseService.showLoading('Getting...')
      this.iapurchase.getProducts(producIds).then((value) => {
        console.log("Get In app Purchase = ", JSON.stringify(value));
        value.forEach(item => {
          this.productList.push(item);
        })
        // loader.dismiss();
      }).catch(error => {
        console.log("Get In app Purchase Error = ", JSON.stringify(error));
      }).finally(() => {
        this.baseService.dismissLoading()
      })
    }
  }

  selectOption = (event, option) => {
    console.log("Select Value ==== ", event.target.value);
    if (option === 'prime') {
      this.selectedOption = 'prime';
      // this.access = 5;
      this.getProducts('prime');
    }
    if (option === 'free') {
      this.selectedOption = 'free';
      // this.access = 0;
    }
    if (option === 'live') {
      this.selectedOption = 'live';
      this.getProducts('live')
    }
    if (option === 'ultra') {
      this.selectedOption = 'ultra';
      this.getProducts('ultra')
    }
  }

  upgradeAccount = () => {
    if (this.access > 4 && this.selectedOption == 'prime') {
      this.baseService.showAlertMessage('Error!', 'You are already a prime user.');
      return;
    }
    if (this.access > 6 && this.selectedOption == 'live') {
      this.baseService.showAlertMessage('Error!', 'You are already a live+ user.');
      return;
    }
    if (this.access > 7 && this.selectedOption == 'ultra') {
      this.baseService.showAlertMessage('Error!', 'You are already an ultra user.');
      return;
    }
    if (this.productList.length == 0) {
      this.baseService.showAlertMessage('Error!', 'App store product list does not exist');
      return;
    }
    this.upgrade(this.productList[0].productId)
  }

  upgrade = (productId) => {
    // if (this.platform.is('ios')) {
      this.baseService.showLoading('Purchasing...')
      this.iapurchase.subscribe(productId).then((value) => {
        console.log("Subscription Result 1 = ", JSON.stringify(value.productType));
        console.log("Subscription Result 2 = ", JSON.stringify(value.transactionId));
        console.log("Subscription Result 3 = ", JSON.stringify(value.signature));
        console.log("Subscription Result 4 = ", JSON.stringify(value.receipt));
        this.userService.upgradeProfile(value.transactionId, this.selectedOption).then(() =>{
          console.log('Upgrade Profile OK!!!!', JSON.stringify(this.selectedOption), JSON.stringify(value.transactionId))
          if (this.navigationData && this.page && this.selectedOption == 'prime') {
            this.baseService.navigateTo(this.page, this.navigationData);
          }
        }).catch(error => {
          console.log('Upgrade Profile Failed!!!!')
        })
        this.modalCtrl.dismiss({purchased: true})
      }).catch(error => {
        this.baseService.showAlertMessage('Something went wrong!', 'Please try again.');
        console.log("Subscription Error = ", JSON.stringify(error));
      })
      .finally(() => {
        // this.router.navigate(['signup']);
        this.baseService.dismissLoading();
      })
    // }
  }

  restorePurchase = () => {
    if (this.platform.is('ios')) {
      this.baseService.showLoading('Restoring...');
      this.iapurchase.restorePurchases().then(value => {
        console.log("Purchase Restoring Result  === ", JSON.stringify(value));
        // loader.dismiss();
        return value;
      }).then(() => {
        this.iapurchase.getReceipt().then(receipt => {
          console.log("Fetch Receipt result = ", JSON.stringify(receipt));
          this.validateReceipt(receipt);
        }).catch(error=>{
          this.baseService.showAlertMessage('Error', 'Not able to get receipt');
        })
      }).catch(error => {
        this.baseService.showAlertMessage('Error', 'Not able to restore your purchase');
      }).finally(() => {
        this.baseService.dismissLoading();
      })
    }
  }

  validateReceipt = (receipt) => {
    this.baseService.showLoading('Validating...')
    this.userService.validateReceipt(receipt).then(value => {
      console.log("Validate Receipt Result === ", JSON.stringify(value));
      this.userService.getProfile();
      this.baseService.showAlertMessage('Success!', 'Your restore purchase was successful!')
    })
    .catch(error => {
      this.baseService.showAlertMessage('Something went wrong', 'Please try again');
      console.log("Validate Receipt Error === ", JSON.stringify(error));
    })
    .finally(() => {
      this.baseService.dismissLoading();
    })
  }

}

import { Component, OnInit } from '@angular/core';
import { IonNav, NavParams } from '@ionic/angular';
import { BaseService } from 'src/app/services/base.service';
import { CreateTeamData } from '../type';
import { CreateTeamDetailPage } from '../create-team-detail/create-team-detail.page';

@Component({
  selector: 'app-create-team-location',
  templateUrl: './create-team-location.page.html',
  styleUrls: ['./create-team-location.page.scss'],
})
export class CreateTeamLocationPage implements OnInit {

  public usStateList: Array<any> = [
    {
      key: "AK",
      value: "Alaska",
    },
    {
      key: "AL",
      value: "Alabama"
    },
    {
      key:"AR",
      value: "Arkansas",
    },
    {
      key: "AZ",
      value: "Arizona",
    },
    {
      key: "CA",
      value: "California"
    },
    {
      key: "CO",
      value: "Colorado"
    },
    {
      key: "CT",
      value: "Connecticut"
    },
    {
      key: "DC",
      value: "District of Columbia"
    },
    {
      key: "DE",
      value: "Delaware"
    },
    {
      key: "FL",
      value: "Florida"
    },
    {
      key: "GA",
      value: "Georgia"
    },
    {
      key: "HI",
      value: "Hawaii"
    },
    {
      key: "IA",
      value: "Iowa"
    },
    {
      key: "ID",
      value: "Idaho"
    },
    {
      key: "IL",
      value: "Illinois"
    },
    {
      key: "IN",
      value: "Indiana"
    },
    {
      key: "KS",
      value: "Kansas"
    },
    {
      key: "KY",
      value: "Kentucky"
    },
    {
      key: "LA",
      value: "Louisiana"
    },
    {
      key: "MA",
      value: "Massachusetts"
    },
    {
      key: "MD",
      value: "Maryland"
    },
    {
      key: "ME",
      value: "Maine"
    },
    {
      key: "MI",
      value: "Michigan"
    },
    {
      key: "MN",
      value: "Minnesota"
    },
    {
      key: "MO",
      value: "Missouri"
    },
    {
      key: "MS",
      value: "Mississippi"
    },
    {
      key: "MT",
      value: "Montana"
    },
    {
      key: "NC",
      value: "North Carolina"
    },
    {
      key: "ND",
      value: "North Dakota"
    },
    {
      key: "NE",
      value: "Nebraska"
    },
    {
      key: "NH",
      value: "New Hampshire"
    },
    {
      key: "NJ",
      value: "New Jersey"
    },
    {
      key: "NM",
      value: "New Mexico"
    },
    {
      key: "NV",
      value: "Nevada"
    },
    {
      key: "NY",
      value: "New York"
    },
    {
      key: "OH",
      value: "Ohio"
    },
    {
      key: "OK",
      value: "Oklahoma"
    },
    {
      key: "OR",
      value: "Oregon"
    },
    {
      key: "PA",
      value: "Pennsylvania"
    },
    {
      key: "RI",
      value: "Rhode Island"
    },
    {
      key: "SC",
      value: "South Carolina"
    },
    {
      key: "SD",
      value: "South Dakota"
    },
    {
      key: "TN",
      value: "Tennessee"
    },
    {
      key: "TX",
      value: "Texas"
    },
    {
      key: "UT",
      value: "Utah"
    },
    {
      key: "VA",
      value: "Virginia"
    },
    {
      key: "VT",
      value: "Vermont"
    },
    {
      key: "WA",
      value: "Washington"
    },
    {
      key: "WI",
      value: "Wisconsin"
    },
    {
      key: "WV",
      value: "West Virginia"
    },
    {
      key: "WY",
      value: "Wyoming"
    }
  ]

  public caProvinceList: Array<any> = [
    {value: "Alberta", key: "AB"},
    {value: "British Columbia", key: "BC"},
    {value: "Manitoba", key: "MB"},
    {value: "New Brunswick", key: "NB"},
    {value: "Newfoundland and Labrador", key: "NL"},
    {value: "Northwest Territories", key: "NT"},
    {value: "Nova Scotia", key: "NS"},
    {value: "Nunavut", key: "NU"},
    {value: "Ontario", key: "ON"},
    {value: "Prince Edward Island", key: "PE"},
    {value: "Quebec", key: "QC"},
    {value: "Saskatchewan", key: "SK"},
    {value: "Yukon", key: "YT"}
  ]

  public currentSelectedCountry: string = 'US';
  public teamData: CreateTeamData = {};
  public zipCode: string = '';
  public selectedState: string = '';
  public city: string = '';

  constructor(
    private nav: IonNav,
    private navParams: NavParams,
    private baseService: BaseService
  ) { }

  ngOnInit() {
    console.log("Navigation Params === ", this.navParams.get('prevData'))
    this.teamData = this.navParams.get('prevData')
    this.teamData.country = this.currentSelectedCountry
  }

  changeZipCode = (event) => {
    if (event.target.value.length > 10) {
      this.baseService.showAlertMessage('Error!', 'Zip cod is not valid!');
      return;
    }
    this.zipCode = event.target.value;
  }

  changeCity = (event) => {
    this.city = event.target.value;
  }

  clickNext = () => {
    // if (this.zipCode == '') {
    //   this.baseService.showAlertMessage('Error!', 'Please input the zip code!');
    //   return;
    // }

    if (this.selectedState == '') {
      this.baseService.showAlertMessage('Error!', 'Please select the state/province name');
      return;
    }

    if (this.city == '') {
      this.baseService.showAlertMessage('Error!', 'Please input the city name!');
      return;
    }

    this.teamData.country = this.currentSelectedCountry;
    if (this.zipCode != '') {
      this.teamData.zip = this.zipCode;
    }
    if (this.currentSelectedCountry === 'US') {
      this.teamData.us_state = this.selectedState
    } else {
      this.teamData.ca_province = this.selectedState
    }
    this.teamData.city = this.city;
    this.nav.push(CreateTeamDetailPage, {prevData: this.teamData})
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArrayConvertPipe } from './array-convert.pipe';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [ArrayConvertPipe],
  exports: [ArrayConvertPipe]
})

export class ArrayConvertPipeModule {

}

import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Router, NavigationExtras, RouterEvent } from '@angular/router';
import { GlobalEmitterService, GlobalEvents } from 'src/app/services/global-emitter.service';
import { Storage } from '@ionic/storage';
import { BaseService } from 'src/app/services/base.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-fab-component',
  templateUrl: './fab-component.component.html',
  styleUrls: ['./fab-component.component.scss'],
})
export class FabComponentComponent implements OnInit {


  @Output()
  public clickClose: EventEmitter<any> =  new EventEmitter<any>();

  public curNavigation = 'following';
  public loggedIn: boolean = false;

  constructor(
    private router: Router,
    private navCtrl: NavController,
    private globalService: GlobalEmitterService,
    private baseService: BaseService,
    private storage: Storage,
    private location: Location
  ) { }

  ngOnInit() {
    // this.globalService.listen(GlobalEvents.userProfiledLoaded).subscribe(() => this.checkProfile());
    // this.router.events.subscribe((event: RouterEvent) => {
    //   this.storage.get('profile').then((result) => {
    //     if (result) {
    //       this.loggedIn = true;
    //     }
    //   })
    //   if (event.url) {
    //     this.curNavigation = event.url.replace('/', '');
    //   }
    // });
  }

  checkProfile = () => {
    this.storage.get('profile').then((result) => {
      if (result) {
        this.loggedIn = true;
      }
    })
  }

  gotoPage = (pageName) => {
    // if (this.loggedIn == false) return;
    if(this.loggedIn == false) {
      this.baseService.showAlertMessage('Error', 'You need to login.');
      return;
    }
    if (this.curNavigation == pageName) {
      return;
    }
    this.curNavigation = pageName;
    this.navCtrl.navigateRoot(pageName);
  }

  goBack = () => {
    this.clickClose.emit();
  }

}

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { NavigationExtras, Router } from '@angular/router';
import { LoadingController, NavController, MenuController, 
  PopoverController, ModalController, AlertController, Platform, IonRouterOutlet } from '@ionic/angular';
import { environment } from '../../../environments/environment';
import { GlobalEmitterService, GlobalEvents } from '../../services/global-emitter.service';
import { AddTeamComponent } from '../add-team/add-team.component';
import { AddPlayerComponent } from '../add-player/add-player.component';
import { TagComponentComponent } from '../tag-component/tag-component.component';
import { VideoUpload } from 'ionic-plugin-video-upload/video-upload/ngx';
import { InAppPurchase2, IAPProduct } from '@ionic-native/in-app-purchase-2/ngx';
import { InAppPurchase } from '@ionic-native/in-app-purchase/ngx';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { UserService } from '../../services/user.service';
import { Storage } from '@ionic/storage';
import { LeagueService } from 'src/app/services/league.service';
import { BaseService } from 'src/app/services/base.service';
import { ModalBaseComponent } from '../manage-modal/modal-base/modal-base.component';
import { ManageHomePage } from '../../pages/manage/manage-home/manage-home.page';
import { UpgradeAccountComponent } from '../upgrade-account/upgrade-account.component';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';


@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit, AfterViewInit {

  public userName: string;
  public userType: string = ''
  public accessLevel: number = 0;
  public myProductId: string = 'com.pitchaware.primemonthly';
  public currentDomain: string = ''

  constructor(
    private authService: AuthService,
    private loadingCtrl: LoadingController,
    private navCtrl: NavController,
    private menuCtrl: MenuController,
    private globalService: GlobalEmitterService,
    private popoverCtrl: PopoverController,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private videoUpload: VideoUpload,
    private platform: Platform,
    private iap: InAppPurchase2,
    private iapurchase: InAppPurchase,
    private router: Router,
    private emailComposer: EmailComposer,
    private storage: Storage,
    private userSerivce: UserService,
    private leagueService: LeagueService,
    private baseService: BaseService,
    private iab: InAppBrowser
  ) { }

  ngOnInit() {
    this.globalService.listen(GlobalEvents.userProfiledLoaded).subscribe(() => this.loadData());
    this.globalService.listen(GlobalEvents.domainLoaded).subscribe((domain) => this.getDomain(domain));
  }

  ngAfterViewInit() {
    
  }

  getDomain = (domian) => {
    console.log("Listened api domain ==== ", domian)
    if (domian.data == 'https://www.pitchaware.com') {
      this.currentDomain = 'PitchAware.com'
    } 

    if (domian.data == 'https://Dev.Pitchaware.com') {
      this.currentDomain = 'Dev.pitchaware.com'
    }
  }

  loadData = () => {
    this.userName = this.userSerivce.userName;
    // this.userType = this.userSerivce.checkPrime == true ? 'Prime User' : 'Normal User'
    if (this.userSerivce.accessValue < 5) this.userType = '';
    if (this.userSerivce.accessValue >= 5) this.userType = 'Prime User';
    if (this.userSerivce.accessValue >= 7) this.userType = 'Live+ User';
    if (this.userSerivce.accessValue >= 9) this.userType = 'Ultra User';
  }

  ionViewWillEnter() {
    
    // this.userType = 
  }

  public async signOut() {
    console.log('SignOut');
    this.menuCtrl.close();
    const loader = await this.loadingCtrl.create({
      spinner: 'circular',
      message: 'Signing out...'
    });

    await loader.present();
    this.authService.signOut().then(() => {
      this.userSerivce.unregisterDevice();
      loader.dismiss();
      this.navCtrl.navigateRoot('new-login');
    });
  }

  addTeam = (event) => {
    this.menuCtrl.toggle();
    // this.modalCtrl.create({
    //   component: AddTeamComponent,
    //   cssClass: 'add-modal',
    // }).then(popOver => {
    //   popOver.present();
    // })
    
    // this.router.navigateByUrl('app/tournaments');
    // // this.router.navigateByUrl('app/tournaments/tournament-schedule', navigationExtras)
    this.leagueService.getTournament(410).then((result) => {
      const navigationExtras: NavigationExtras = {
        state: {
          tournamentId: 410,
          tournament: result.event
        }
      }
      this.router.navigateByUrl('app/tournaments/tournament-schedule', navigationExtras)
    })
    // this.leagueService.getTournamentDataAndGo(410)
  }

  addPlayer = (event) => {
    this.menuCtrl.toggle();
    this.modalCtrl.create({
      component: AddPlayerComponent,
      cssClass: 'add-modal',
    }).then(popOver => {
      popOver.present();
    })
  }

  filePicker = (event) => {
    // const uploadURL = 
    // this.videoUpload.initImagePicker()
  }

  upgrade = () => {
    this.menuCtrl.close();
    // this.router.navigateByUrl('purchase');
    this.modalCtrl.create({
      component: UpgradeAccountComponent,
      cssClass: 'add-modal',
      swipeToClose: false
    }).then(popOver => {
      popOver.present();
    })
  }

  support = () => {
    // let email = {
    //   to: '',
    //   cc: 'support@pitchaware.com',
    //   bcc: [],
    //   subject: 'Need Help!',
    //   body: '',
    //   isHtml: true
    // }
    
    // // Send a text message using default options
    // this.emailComposer.open(email).catch(error => {
    //   console.log('Email Open Error === ', JSON.stringify(error));
    // });

    this.iab.create("https://PitchAware.zendesk.com", "_system")
  }

  tag = () => {
    this.menuCtrl.toggle();
    this.modalCtrl.create({
      component: TagComponentComponent,
      cssClass: 'add-modal',
    }).then(popOver => {
      popOver.present();
    })
  }

  gotoFavorites = () => {
    this.menuCtrl.toggle();
    this.router.navigateByUrl('app/home');
  }

  gotoHome = () => {
    this.menuCtrl.toggle();
    this.router.navigateByUrl('app/main');
  }

  gotoEvents = () => {
    this.menuCtrl.toggle();
    this.router.navigateByUrl('app/find');
  }

  manageTeams = () => {
    this.menuCtrl.toggle();
    // this.router.navigateByUrl('teams-admin');
    this.baseService.navigateTo('teams-admin')
    
  }

  createTeam = () => {
    this.menuCtrl.toggle();
    this.modalCtrl.create({
      component: ModalBaseComponent,
      componentProps: {
        rootPage: ManageHomePage
      }
    }).then(popOver => {
      popOver.present();
    })
    // this.leagueService.getTournament(410).then((result) => {
    //   const navigationExtras: NavigationExtras = {
    //     state: {
    //       tournamentId: 410,
    //       tournament: result.event,
    //       source: 'notification'
    //     }
    //   }
    //   this.router.navigateByUrl('app/tournaments/notifications', navigationExtras)
    // })
  }

  changeAppType = () => {
    this.menuCtrl.toggle();
    this.baseService.showAppTypeSelection();
  }

  faq = () => {
    // window.open("https://PicthAware.zendesk.com", "_system");
    
  }

  uploadHistory = () => {
    this.menuCtrl.toggle();
    this.baseService.navigateTo('video-history')
  }

}

import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, NEVER, throwError, from } from 'rxjs';
import { catchError, retry, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';
import { AlertController, LoadingController, NavController } from '@ionic/angular';
import { BaseService } from '../base.service';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class RefreshTokenService {
  public loading: boolean = false;

  constructor(
    private authService: AuthService,
    private baseService: BaseService,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private navCtrl: NavController,
    private storage: Storage
  ) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let attemps = 0;
    const maxAttemps = 2;

    return next.handle(req)
    .pipe(catchError((err: HttpErrorResponse) => {

      if (attemps > maxAttemps) {
        this.forceLogout();
        return NEVER;
      }


      if (err.status === 401 || err.status == 403) {
        console.log('401 Error case Current Attemps ===== ', attemps);
        if (!req.url.includes('auth') && !req.url.includes('signup') && !req.url.includes('reset_password') && !req.url.includes('/o/token') && !req.url.includes('.json')
         && req.url.includes(this.baseService.apiDomain)) {
          console.log('Api Available Error case Current Attemps ===== ', attemps);
          return this.authService.refreshToken()
          // .catch(error => {
          //   console.log("@@@@@@@@@@");
          //   this.forceLogout();
          //   return NEVER;
          // }).then(response => {
          //   console.log("!!!!!!!!!!!", response);
          //   const newRequest = req.clone({
          //     setHeaders: {
          //       Authorization: `Bearer ${response.data.access_token}`
          //     }
          //   })
          //   return next.handle(newRequest);
          // });
          .pipe(retry(1))
          .pipe(catchError((error) => {
            // if attempt to refresh token itself fails then also cancelling further request
            console.log('Refresh Token is failed!!', error);
            this.forceLogout();
            return NEVER;
          }))
          .pipe(switchMap(response => {
            console.log('Intercept Response ==== ', response);
            attemps++;
            const newRequest = req.clone({
              setHeaders: {
                Authorization: `Bearer ${response.data.access_token}`
              }
            });
            return next.handle(newRequest);
          }));
        } else {
          console.log("Exception case for normal error ===== ")
          if (!req.url.includes('.json')) {
            console.log('Refresh Token is ignored!!');
            this.forceLogout();
            return NEVER;
          } else {
            return throwError(err.error);
          }
        }
      }
      return throwError(err);
    }));
  }

  public forceLogout() {

    // Dismiss Current Loading Controller if exists
    // from(this.loadingCtrl.dismiss()).pipe(catchError(() => NEVER)).subscribe();
    // Dismiss Current Loading Controller if exists
    // from(this.alertCtrl.dismiss()).pipe(catchError(() => NEVER)).subscribe();

    // Local Stroage Clear
    window.localStorage.clear();

    if (this.baseService.gameCastInterval) {
      clearInterval(this.baseService.gameCastInterval);
      this.baseService.gameCastInterval = null;
    }

    if (this.baseService.tournamentScheduleInterval) {
      clearInterval(this.baseService.tournamentScheduleInterval);
      this.baseService.tournamentScheduleInterval = null;
    }
    this.baseService.dismissLoading().then(() => {
      // this.baseService.dismissAlert().then(() => {
        
      // })
      this.baseService.showAlertMessage('Session Expired!', 'You should sign in again');
    })

    
    
    this.storage.clear().then(() => {
      // if (this.authService.faceIdAvailable && !this.authService.bioSkip) {
      //   // this.navCtrl.navigateRoot('bio-sign');
      //   this.authService.loginWithPreviousCredentials();
      // } else {
        this.navCtrl.navigateRoot('new-login');
      // }
    });
  }
}

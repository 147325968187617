import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { BaseService } from 'src/app/services/base.service';
import { OneTimePurchaseItem, PurchaseData, UserService } from 'src/app/services/user.service';
import { InAppPurchase } from '@ionic-native/in-app-purchase/ngx';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-one-time-purchase',
  templateUrl: './one-time-purchase.page.html',
  styleUrls: ['./one-time-purchase.page.scss'],
})
export class OneTimePurchasePage implements OnInit {

  @Input()
  public navigationData: NavigationExtras = undefined;

  @Input()
  public page: string = undefined;

  @Input()
  public json: string = undefined

  @Input()
  public keys: Array<string> = undefined

  @Output()
  public purchased: EventEmitter<any> = new EventEmitter<any>();

  public currentItem: OneTimePurchaseItem;
  public productList: Array<any> = [];
  public items: Array<OneTimePurchaseItem> = [];

  constructor(
    private modalCtrl: ModalController,
    private userService: UserService,
    private baseService: BaseService,
    private iapurchase: InAppPurchase,
    private platform: Platform
  ) { }

  ngOnInit() {
  }

  getProducts = (item: OneTimePurchaseItem) => {
    let productId = item.web.id.toString();
    if (this.platform.is('ios')) {
      productId = item.ios.id.pa;
    }
    if (this.platform.is('android')) {
      productId = item.android.id.pa;
    }
    const productIds = [productId];
    this.baseService.showLoading('Getting...')
    this.iapurchase.getProducts(productIds).then((value) => {
      console.log("Get In app Purchase = ", JSON.stringify(value));
      this.productList = [];
      value.forEach(pItem => {
        this.productList.push(pItem);
      })
      // loader.dismiss();
    }).catch(error => {
      console.log("Get In app Purchase Error = ", JSON.stringify(error));
    }).finally(() => {
      this.baseService.dismissLoading()
    })
  }

  ionViewWillEnter() {
    if (this.json && !this.keys) {
      this.userService.getOneTimeJSONPurchaseItems(this.json).then(data => {
        const items: Array<OneTimePurchaseItem> = data.details;
        if (items.length > 0)
          this.currentItem = items[0];
          this.items = items;
          this.getProducts(this.currentItem)
      })
    }
    if (!this.json && this.keys) {
      this.userService.getOneTimePurchaseItems(this.keys).then(data => {
        const items: Array<OneTimePurchaseItem> = data.details;
        if (items.length > 0)
          this.currentItem = items[0];
          this.items = items;
          this.getProducts(this.currentItem)
      })

      // const items: Array<OneTimePurchaseItem> = [
      //       {
      //           "name": "Event Pass",
      //           "tagline": "Access to all Premium Video Content for this Event",
      //           "list": [
      //               "All Game Live Streams",
      //               "VOD for every Camera for Every Game available for up to 7 Days",
      //               "Download full VOD Streams",
      //               "Highlight Clips created for Every Pitch Automagically",
      //               "Player Profiles with video Playlists automatically",
      //               "Game Highlight Playlists",
      //               "Download Clips to your Device"
      //           ],
      //           "ios": {
      //               "id": {
      //                   "pa": "com.pitchaware.cs46",
      //                   "awre": "com.awaresports.cs46"
      //               },
      //               "price": "70.00"
      //           },
      //           "android": {
      //               "id": {
      //                   "pa": "com.pitchaware.cs46",
      //                   "awre": "com.awaresports.cs46"
      //               },
      //               "price": "70.00"
      //           },
      //           "web": {
      //               "id": 46,
      //               "price": "70.00"
      //           }
      //       },
      //       {
      //           "name": "Creekside Pass",
      //           "tagline": "Access to all Premium Video Content at this Venue during 2021",
      //           "list": [
      //               "All Game Live Streams",
      //               "VOD for every Camera for Every Game available for up to 7 Days",
      //               "Download full VOD Streams",
      //               "Highlight Clips created for Every Pitch Automagically",
      //               "Player Profiles with video Playlists automatically",
      //               "Game Highlight Playlists",
      //               "Download Clips to your Device"
      //           ],
      //           "ios": {
      //               "id": {
      //                   "pa": "com.pitchaware.cs48",
      //                   "awre": "com.awaresports.cs48"
      //               },
      //               "price": "150.00"
      //           },
      //           "android": {
      //               "id": {
      //                   "pa": "com.pitchaware.cs48",
      //                   "awre": "com.awaresports.cs48"
      //               },
      //               "price": "150.00"
      //           },
      //           "web": {
      //               "id": 48,
      //               "price": "150.00"
      //           }
      //       }
      //   ]
      // if (items.length > 0)
      //   this.currentItem = items[0];
      //   this.items = items;
      //   this.getProducts(this.currentItem)
    }
  }

  closeModal = () => {
    this.modalCtrl.dismiss({purchased: false});
  }

  purchase = () => {
    this.baseService.showLoading('Purchasing...')
    this.iapurchase.buy(this.productList[0].productId).then((value) => {
      console.log("Subscription Result 1 = ", JSON.stringify(value.productType));
      console.log("Subscription Result 2 = ", JSON.stringify(value.transactionId));
      console.log("Subscription Result 3 = ", JSON.stringify(value.signature));
      console.log("Subscription Result 4 = ", JSON.stringify(value.receipt));
      const purchaseData: PurchaseData = {
        event_id: this.currentItem.event_id,
        venue_id: this.currentItem.venue_id,
        product_code: this.productList[0].productId,
        tid: value.transactionId
      }
      this.userService.upgradeProfileAfterPurchase(purchaseData);
      if (this.navigationData && this.page) {
        this.baseService.navigateTo(this.page, this.navigationData);
      }
      this.modalCtrl.dismiss({purchased: true})
    }).catch(error => {
      this.modalCtrl.dismiss({purchased: false})
      this.baseService.showAlertMessage('Something went wrong!', 'Please try again.');
      console.log("Subscription Error = ", JSON.stringify(error));
    })
    .finally(() => {
      this.baseService.dismissLoading();
    })
  }

  testPurchase = () => {
    this.modalCtrl.dismiss({purchased: true})
  }

  chooseItem = (item: OneTimePurchaseItem) => {
    this.currentItem = item;
    this.getProducts(this.currentItem);
  }

}

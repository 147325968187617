import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ErrorFormat, BaseService } from './base.service';
import { LOCAL_CACHE_DISABLE } from '../common/constant';
import { GlobalEmitterService, GlobalEvents } from './global-emitter.service';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Push, PushObject, PushOptions } from '@ionic-native/push/ngx';
import { NavigationService } from './navigation.service';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';

export interface AccessData {
  is_scout_coach: boolean;
  is_scout_player: boolean;
  level: number;
  type: number;
  what: string;
}

export interface FacilityData {
  admin_level: string;
  join_link: string;
  logo_url: string;
  pk: string;
  scoreboard_name: string;
  team_name: string;
}

export interface SeasonData {
  pk: number;
  year: number;
}

export interface CoachAccountData {
  coach_level: string;
  join_link: string;
  logo_url: string;
  pk: string;
  public_name: string;
  scoreboard_name: string;
  team_name: string;
  seasons: Array<SeasonData> | null;
}

export interface TeamData {
  pk: number;
  name: string;
  team_type: string;
}

export interface TopboxData {
  key: string;
  name: string;
  type: string;
}

export interface ProfileData {
  pk: number;
  player: string | number | null;
  routed_token: string;
  team: CoachAccountData | null;
  notification_unreads: number;
  logo_url: string | any;
  league: null | string | number;
  last_name: string;
  first_name: string;
  full_name: string;
  is_scorekeeper: boolean;
  has_uploads: boolean;
  favorite_teams: Array<TeamData> | null;
  facility: FacilityData | null;
  coach_accounts: Array<CoachAccountData> | null;
  board_unreads: number;
  access: AccessData;
  access_keys?: Array<string>;
  top_boxes: Array<TopboxData> | null | any;
  favorites_playlist_key: string;
  following_playlist_key: string;
  ultra_available?: boolean
}

export interface CWSData {
  id: number;
  name: string;
}

export interface PlayerData {
  pk: number;
  has_account: boolean;
  is_hitter: boolean;
  is_pitcher: boolean;
  logo_url: string;
  team_name: string;
  team_type: string;
  who_id: number;
  who_name: string;
  who_type: string;
  added?: boolean;
}

export interface MyTeamData {
  pk: number;
  name: string;
  logo_url: string | null;
  team_type: string;
  added?: boolean;
}

export interface TeamDisplayData{
  logo_url: string;
  name: string;
}

export interface GameDetailData {
  away: TeamDisplayData;
  home: TeamDisplayData;
  display_versus?: string;
  focus_name?: string;
  primary_line?: string;
}

export interface LeagueDisplayData {
  pk: number;
  name: string;
}

export interface GameRoutingData {
  game_hash: string;
  stats_url: string | null;
}

export interface GameData {
  data_level: number;
  display_details: GameDetailData;
  display_name_w_date: string;
  display_versus: string;
  final_score: string | number;
  game_date: string;
  game_page_url: string;
  game_time: number | string | null;
  generic_name: string;
  has_scout: boolean;
  has_scout_access: boolean;
  has_started: boolean;
  i_can_score: boolean;
  is_finished: boolean;
  league: LeagueDisplayData | null;
  location_name: string;
  og: string | number | null;
  opponent_name: string;
  pk: number;
  routing: GameRoutingData | null;
  stats_game_status: number;
  timestamp: number;
  version: number;
}

export interface UpcomingGameData {
  teams: Array<GameData>;
}

export interface FollowingData {
  cws: Array<CWSData>;
  facilities: Array<any>;
  myteams: Array<MyTeamData>;
  players: Array<PlayerData>;
  // teams: Array<PlayerData>;
  videos: Array<any>;
  upcoming_games: UpcomingGameData;
}

export interface TeamEventData {
  can_add: boolean;
  name: string;
  previous: Array<EventData>;
  team_id: number;
  upcoming: Array<EventData>;
}

export interface RouteData {
  game_hash: string;
  stats_url: null | string;
}

export interface LeagueData {
  name: string;
  pk: number;
}

export interface EventData {
  pk: number;
  data_level: number;
  display_name_w_date: string;
  display_details: GameDetailData;
  display_versus: string;
  final_score: string | number;
  game_date: string;
  game_page_url: string;
  game_time: string | number | null;
  generic_name: string;
  has_scout: boolean;
  has_scout_access: boolean;
  has_started: boolean;
  i_can_score: boolean;
  is_finished: boolean;
  league: string | null | LeagueData;
  location_name: string | null;
  og: string | null;
  opponent_name: string;
  stats_game_status: number;
  version: number;
  timestamp: number;
  routing: RouteData;
}

export interface ScheduleData {
  teams: Array<TeamEventData>;
}

export interface TeamSearchResult {
  $$hash_key: string;
  pk: number;
  name: string;
  team_type: string;
  logo_url: string;
}

export interface PlayerSearchResult {
  $$hash_key: string;
  pk: number;
  name: string;
  player_id: number;
  team: string;
  logo_url: string;
}

export enum UserDataPermission {
  ACCESSIBLE = 'ACCESSIBLE',
  NEED_SUBSCRIBE = 'NEED_SUBSCRIBE',
  NEED_ONE_TIME = 'NEED_ONE_TIME'
}

export interface OneTimePurchaseItem {
  name: string,
  tagline: string,
  list: Array<string>,
  event_id?: number,
  venue_id?: number,
  ios: {
    id: {
      pa: string,
      awre: string
    },
    price: string
  },
  android: {
    id: {
      pa: string,
      awre: string
    },
    price: string
  },
  web: {
    id: number,
    price: string
  }
}

export interface PurchaseData {
  venue_id: number | string,
  event_id: number | string,
  product_code: string,
  tid: string
}

export interface SearchResult {
  items: Array<TeamSearchResult> | Array<PlayerSearchResult>;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public userName: string = '';
  public checkPrime: boolean = false;
  public accessValue: number = 0;
  public access_keys: Array<string> = [];
  public pushObject: PushObject = undefined;

  constructor(
    private http: HttpClient,
    private baseService: BaseService,
    private globalService: GlobalEmitterService,
    private platform: Platform,
    private storage: Storage,
    private push: Push,
    private navigationService: NavigationService,
    private fa: FirebaseAnalytics,
  ) { }

  public getProfile(): Promise<ProfileData|ErrorFormat> {
    const url = this.baseService.apiDomain + environment.profileUrl;
    // let headers = this.baseService.Headers(true, true);
    return this.http.get<ProfileData>(url).toPromise().then((result: ProfileData) => {
      console.log('Get Profile Result ==== ', result);
      this.userName = result.full_name;
      this.checkPrime = result.access && result.access.level >= 5 ? true : false;
      this.accessValue = result?.access?.level || 0;
      this.access_keys = result.access_keys
      // Save the profile to the window local storage.
      window.localStorage.setItem('profile', JSON.stringify(result));
      this.storage.set('profile', JSON.stringify(result)).then(() => {
        this.globalService.emit(GlobalEvents.userProfiledLoaded);
      })
      
      return result;
    }).catch(this.baseService.handleError);
  }

  public getFavorites(disableCache = false): Promise<any> {
    const url = this.baseService.apiDomain + '/api/following?timestamp=' + Date.now();
    let headers = new HttpHeaders();
    if (disableCache) {
      headers = headers.set(LOCAL_CACHE_DISABLE, '1');
    }
    return this.http.get<FollowingData>(url).toPromise().then((result) => {
      console.log('Get Following Result === ', result);
      return result;
    }).catch(this.baseService.handleError);
  }

  public getFavoriteData(playerList, disableCache = false): Promise<any> {
    const url = this.baseService.apiDomain + '/api/following?timestamp=' + Date.now();
    let headers = new HttpHeaders();
    if (disableCache) {
      headers = headers.set(LOCAL_CACHE_DISABLE, '1');
    }
    let body = new FormData();
    body.append('pids', playerList)
    return this.http.post<any>(url, body, {headers}).toPromise().then((result) => {
      console.log('Get Following Data Result === ', result);
      return result;
    }).catch(this.baseService.handleError);
  }

  public getSchedule(): Promise<ScheduleData|ErrorFormat> {
    const url = this.baseService.apiDomain + '/api/schedule';
    return this.http.get<ScheduleData>(url).toPromise().then((result) => {
      console.log('Get Schedule Result ==== ', result);
      return result;
    }).catch(this.baseService.handleError);
  }

  public  getTeamSearchResult(search): Promise<SearchResult | ErrorFormat> {
    const url = this.baseService.apiDomain + '/api/search/team';
    let params = new HttpParams().set('myteam', '1');
    params = params.set('search', search);
    return this.http.get<SearchResult>(url, {params}).toPromise().then((result) => {
      console.log('Get Team Search Result  === ', result);
      return result;
    }).catch(this.baseService.handleError);
  }

  public getPlayerSearchResult(search): Promise<SearchResult | ErrorFormat> {
    const url = this.baseService.apiDomain + '/api/search/player';
    let params = new HttpParams().set('myteam', '1');
    params = params.set('search', search);
    return this.http.get<SearchResult>(url, {params}).toPromise().then((result) => {
      console.log('Get Player Search Result  === ', result);
      return result;
    }).catch(this.baseService.handleError);
  }

  public bumpIt(aid): Promise<any> {
    const url = this.baseService.apiDomain + '/api/like_change?aid=' + aid;
    return this.http.get<any>(url).toPromise().then((result) => {
      console.log('Bump Result  === ', result);
      return result;
    }).catch(this.baseService.handleError);
  }

  public unBumpIt(aid): Promise<any> {
    const url = this.baseService.apiDomain + '/api/like_change?aid=' + aid;
    return this.http.get<any>(url).toPromise().then((result) => {
      console.log('Bump Result  === ', result);
      return result;
    }).catch(this.baseService.handleError);
  }

  public signup(userData): Promise <any> {
    const url = this.baseService.apiDomain + '/api/signup/';
    return this.http.post<any>(url, userData).toPromise().then((result) => {
      console.log('Signup Result  === ', result);
      return result;
    }).catch(this.baseService.handleError);
  }

  public changePassword(password1, password2) {
    const url = this.baseService.apiDomain + '/api/profile/change-pwd';
    return this.http.post<any>(url, {password1, password2}).toPromise().then((result) => {
      console.log('Change Password Result  === ', result);
      return result;
    }).catch(this.baseService.handleError);
  }

  public resetPassword(email) {
    const url = this.baseService.apiDomain + '/api/reset_password';
    return this.http.post<any>(url, {email}).toPromise().then((result) => {
      console.log('Reset Password Result  === ', result);
      return result;
    }).catch(this.baseService.handleError);
  }

  public checkJSONAccess(json: string, gameId: string): Promise<UserDataPermission | ErrorFormat> {
    const resultHandle = (result): UserDataPermission => {
      const content_keys = result.access_keys;
      console.log("Key Comparing #### ", content_keys, this.access_keys)
      if (!content_keys || content_keys.length === 0) return UserDataPermission.ACCESSIBLE;
      for (let key of content_keys) {
        for (let userKey of this.access_keys) {
          
          if (userKey.toLowerCase() == key.toLowerCase()) return UserDataPermission.ACCESSIBLE;
        }
      }
      for (let key of content_keys) {
        if (key.toLowerCase() == 'prime') return UserDataPermission.NEED_SUBSCRIBE;
      }
      return UserDataPermission.NEED_ONE_TIME;
    }
    const basicURL = this.baseService.apiDomain + '/api/game/basic/' + gameId + '?timestamp=' + Date.now();
    return new Promise((resolve, reject) => {
      this.http.get<any>(json).toPromise().then(resultHandle)
      .then(result => resolve(result))
      .catch((error) => {
        this.http.get<any>(basicURL).toPromise().then((val) => {
          this.http.get<any>(json).toPromise().then(resultHandle).then(res => resolve(res))
          .catch((error) => reject(error));
        }).catch(err => reject(err));
      });
    })
  }

  public checkAccessbility(content_keys: Array<string>): UserDataPermission {
    if (!content_keys || content_keys.length === 0) return UserDataPermission.ACCESSIBLE;
    for (let key of content_keys) {
      for (let userKey of this.access_keys) {
        if (userKey.toLowerCase() == key.toLowerCase()) return UserDataPermission.ACCESSIBLE;
      }
    }
    for (let key of content_keys) {
      if (key.toLowerCase() == 'prime') return UserDataPermission.NEED_SUBSCRIBE;
    }

    return UserDataPermission.NEED_ONE_TIME;
  }

  public getOneTimeJSONPurchaseItems(json: string): Promise<any> {
    return this.http.get<any>(json).toPromise().then((result) => {
      if (!result.access_keys || !result.access_keys.length) return {};
      let url = this.baseService.apiDomain + '/api/access/premium/?access_keys=';
      const keysStr = result.access_keys.join(',')
      url = url + keysStr;
      return this.http.get<any>(url).toPromise().then((result) => {
        console.log('Get One Time Purchase Result  === ', JSON.stringify(result));
        return result;
      }).catch(this.baseService.handleError);
    }).catch(this.baseService.handleError);
  }

  public getOneTimePurchaseItems(keys: Array<string>): Promise<any> {
    let url = this.baseService.apiDomain + '/api/access/premium/?access_keys=';
    const keysStr = keys.join(',')
    url = url + keysStr;
    return this.http.get<any>(url).toPromise().then((result) => {
      console.log('Get One Time Purchase Result  === ', JSON.stringify(result));
      return result;
    }).catch(this.baseService.handleError);
  }

  public initPushObject(): Promise<any> {
    return new Promise((resolve, reject) => {
      const options: PushOptions = {
        android: {},
        ios: {
            alert: 'true',
            badge: true,
            sound: true
        },
        windows: {},
        browser: {
            pushServiceURL: ''
        }
      }
      this.pushObject = this.push.init(options);
      this.pushObject.on('notification').subscribe((notification: any) => {
        console.log("Push Notification data === ", JSON.stringify(notification));
        console.log(notification.message);
        console.log(notification.title);
        console.log(notification.count);
        console.log(notification.sound);
        console.log(notification.image);
        console.log(notification.additionalData);
        const notificationData = notification.additionalData;
        this.navigationService.checkAndGo(notificationData);
      });
      this.push.hasPermission().then(result => {
        if (result.isEnabled) {
        } else {
          this.storage.get('device_token').then(token => {
            if (token && token != '') {
              this.unregisterDevice();
            }
          })
        }
      })
      this.pushObject.on('registration').subscribe((registration: any) => {
        console.log("Device Registered: ", JSON.stringify(registration));
        this.storage.set('device_token', registration.registrationId);
        this.storage.get('profile').then(result => {
          console.log("Get Profile with Push $$$$$ ", JSON.stringify(result))
          if (result) {
            const profileData: ProfileData = JSON.parse(result)
            this.registerDevice(registration.registrationId, profileData.pk).then((reg) => {
              console.log("Register Device with Push $$$$ ", JSON.stringify(reg))
              resolve(true);
            }).catch(error => {
              console.log("Register Device Error with Push $$$$ ", JSON.stringify(error))
              this.fa.logEvent('PUSH_REGISTRATION_ERROR', {
                error, platfom: this.platform.is('ios') ? 'ios' : 'android'}
              );
              reject(false);
            });
          }
        })
      });
    })
  }

  public registerDevice(token:string, userPK: number) : Promise<any> {
    let platformUrl = "";
    let body = "";
    if (this.platform.is('ios')) {
      platformUrl = "/api/device/apns/"
      body = encodeURIComponent('registration_id') + '=' + encodeURIComponent(token) + '&' + encodeURIComponent('application_id') + '=' + encodeURIComponent('pa') + '&' + encodeURIComponent('user') + '=' + encodeURIComponent(userPK);
    }
    if (this.platform.is('android')) {
      platformUrl = "/api/device/gcm/"
      body = encodeURIComponent('registration_id') + '=' + encodeURIComponent(token) + '&' + encodeURIComponent('application_id') + '=' + encodeURIComponent('pa_fcm') + '&' + encodeURIComponent('user') + '=' + encodeURIComponent(userPK);
    }
    let url = this.baseService.apiDomain + platformUrl;
    
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    
    return this.http.post<any>(url, body.toString(), {
      headers: headers
    }).toPromise().then(result => {
      console.log("Token Registration Result === ", JSON.stringify(result));
      window.localStorage.setItem('currentTokenId', result.data);
      return result;
    });
  }

  public unregisterDevice() {
    this.storage.get('device_token').then(token => {
      if (token && token != '') {
        let platformUrl = "";
        if (token) {
          if (this.platform.is('ios')) {
            platformUrl = "/api/device/apns/";
          }

          if (this.platform.is('android')) {
            platformUrl = "/api/device/gcm/";
          }
        }

        let url = this.baseService.apiDomain + platformUrl + token +  '/';
        
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
        
        this.http.delete(url, {
          headers: headers
        }).toPromise().then(result => {
          this.storage.set('device_token', '')
        });
      }
    })
    
  }

  public upgradeProfile(transactionId, productId): Promise<any> {
    let url = '';
    if (productId.includes('prime')) {
      url = this.baseService.apiDomain + '/api/upgrade-profile/';
    } else if (productId.includes('live')) {
      url = this.baseService.apiDomain + '/api/upgrade-profile/live';
    } else if (productId.includes('ultra')) {
      url = this.baseService.apiDomain + '/api/upgrade-profile/ultra';
    } else {
      url = this.baseService.apiDomain + '/api/upgrade-profile/';
    }
    
    let body = encodeURIComponent('tid') + '=' + encodeURIComponent(transactionId);
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.post<any>(url, body.toString(), {
      headers: headers
    }).toPromise().then(result => {
      console.log("Profile Upgrade Result === ", JSON.stringify(result) + JSON.stringify(url));
      this.userName = result.full_name;
      this.checkPrime = result.access && result.access.level >= 5 ? true : false;
      this.accessValue = result?.access?.level || 0;
      this.access_keys = result?.access_keys || [];
      window.localStorage.setItem('profile', JSON.stringify(result));
      this.storage.set('profile', JSON.stringify(result)).then(() => {
        this.globalService.emit(GlobalEvents.userProfiledLoaded);
      })
      return result;
    });
  }

  public upgradeProfileAfterPurchase(purchaseData: PurchaseData): Promise<any> {
    let url = this.baseService.apiDomain + '/api/purchase-pass/';
    const formData = new FormData()
    formData.set('venue_id', purchaseData.venue_id.toString());
    formData.set('event_id', purchaseData.event_id.toString());
    formData.set('product_code', purchaseData.product_code);
    formData.set('tid', purchaseData.tid);
    return this.http.post<any>(url, formData).toPromise().then((result) => {
      console.log('Profile Upgrade Result === ', JSON.stringify(result));
      this.userName = result.full_name;
      this.checkPrime = result.access && result.access.level >= 5 ? true : false;
      this.accessValue = result?.access?.level || 0;
      this.access_keys = result?.access_keys || [];
      window.localStorage.setItem('profile', JSON.stringify(result));
      this.storage.set('profile', JSON.stringify(result)).then(() => {
        this.globalService.emit(GlobalEvents.userProfiledLoaded);
      })
      return result;
    }).catch(error => {
      console.log('Profile Upgrade Error === ', JSON.stringify(error));
    });
  }

  public validateReceipt(receipt): Promise<any> {
    const url = this.baseService.apiDomain + '/api/validate-receipt/';
    let body = encodeURIComponent('receipt') + '=' + encodeURIComponent(receipt);
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.post<any>(url, body.toString(), {
      headers: headers
    }).toPromise().then(result => {
      console.log("Validate Receipt Result === ", JSON.stringify(result));
      return result;
    });
  }

  public getNotifications(): Promise<any> {
    const url = this.baseService.apiDomain + '/api/notifications';
    return this.http.get<any>(url).toPromise().then((result) => {
      console.log('Notifications Result  === ', result);
      return result;
    }).catch(this.baseService.handleError);
  }
}

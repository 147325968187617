import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { UpgradeAccountComponent } from './upgrade-account.component';
import { TimeCheckModule } from '../../pipes/time-check.module';
import { TimeChangePipeModule } from '../../pipes/time-change.module';

@NgModule({
  imports: [ CommonModule, FormsModule, IonicModule, TimeCheckModule, TimeChangePipeModule ],
  declarations: [UpgradeAccountComponent],
  exports: [UpgradeAccountComponent]
})
export class UpgradeAccountComponentModule {}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BaseService } from '../../services/base.service';
import _ from 'lodash';

interface ItemData {
  value: string;
  selected: boolean;
  key: string;
  level?: number // Section category: Result, Pitch Type, Velocity, Count
}

interface DisplayObject {
  [id: string]: ItemData
}

@Component({
  selector: 'app-tag-component',
  templateUrl: './tag-component.component.html',
  styleUrls: ['./tag-component.component.scss'],
})
export class TagComponentComponent implements OnInit {

  @Input()
  public tagArray: Array<any> = [];

  @Input()
  public selectedTags: Array<any> = [];

  @Input()
  public playerOptionExist: boolean = false;

  @Output()
  public applyFilter: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public playerTypeFilter: EventEmitter<string> = new EventEmitter<string>();

  public inputTags: DisplayObject = {};
  public tagsObject: DisplayObject = {};
  public searchResult: DisplayObject = {};
  public checkedTags: DisplayObject = {};
  public diffTags: DisplayObject = {};
  public playerTypeObject: DisplayObject = {};
  public diffExist: boolean = false;
  public searchExist: boolean = false;
  public checkExist: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private baseService: BaseService,
  ) { }

  ngOnInit() {
    this.tagsObject = {
      'r11': {
        value: 'Single',
        selected: false,
        key: 'r11',
        level: 0
      },
      'r12': {
        value: 'Double',
        selected: false,
        key: 'r12',
        level: 0
      },
      'r13': {
        value: 'Triple',
        selected: false,
        key: 'r13',
        level: 0
      },
      'r14': {
        value: 'Home Run',
        selected: false,
        key: 'r14',
        level: 0
      },
      'r21': {
        value: 'Out',
        selected: false,
        key: 'r21',
        level: 0
      },
      'p1': {
        value: 'Fast Ball',
        selected: false,
        key: 'p1',
        level: 1
      },
      'p30': {
        value: 'Breaking Ball',
        selected: false,
        key: 'p31',
        level: 1
      },
      'p11': {
        value: 'Changeup',
        selected: false,
        key: 'p11',
        level: 1
      },
      'v60': {
        value: '<70',
        selected: false,
        key: 'v60',
        level: 2
      },
      'v70': {
        value: '70-79',
        selected: false,
        key: 'v70',
        level: 2
      },
      'v80': {
        value: '80-84',
        selected: false,
        key: 'v80',
        level: 2
      },
      'v85': {
        value: '85-89',
        selected: false,
        key: 'v85',
        level: 2
      },
      'v90': {
        value: '90+',
        selected: false,
        key: 'v90',
        level: 2
      },
      'v95': {
        value: '95+',
        selected: false,
        key: 'v95',
        level: 2
      },
      'c00': {
        value: '0-0',
        selected: false,
        key: 'c00',
        level: 3
      },
      'c10': {
        value: '1-0',
        selected: false,
        key: 'c10',
        level: 3
      },
      'c20': {
        value: '2-0',
        selected: false,
        key: 'c20',
        level: 3
      },
      'c30': {
        value: '3-0',
        selected: false,
        key: 'c30',
        level: 3
      },
      'c01': {
        value: '0-1',
        selected: false,
        key: 'c01',
        level: 3
      },
      'c11': {
        value: '1-1',
        selected: false,
        key: 'c11',
        level: 3
      },
      'c21': {
        value: '2-1',
        selected: false,
        key: 'c21',
        level: 3
      },
      'c31': {
        value: '3-1',
        selected: false,
        key: 'c31',
        level: 3
      },
      'c02': {
        value: '0-2',
        selected: false,
        key: 'c02',
        level: 3
      },
      'c12': {
        value: '1-2',
        selected: false,
        key: 'c12',
        level: 3
      },
      'c22': {
        value: '2-2',
        selected: false,
        key: 'c22',
        level: 3
      },
      'c32': {
        value: '3-2',
        selected: false,
        key: 'c32',
        level: 3
      }
    }

    this.playerTypeObject = {
      'hitterPITCHAWARE': {
        value: 'Hitter',
        selected: false,
        key: 'hitterPITCHAWARE',
      },
      'pitcherPITCHWARE': {
        value: 'Pitcher',
        selected: false,
        key: 'pitcherPITCHWARE'
      }
    }

    this.tagArray.forEach(tag => {
      let item: ItemData;
      if (this.tagsObject[tag.key]) {
        item = {
          value: tag.value ? tag.value : tag.name,
          key: tag.key,
          selected: false,
          level: this.tagsObject[tag.key].level
        }
      } else {
        item = {
          value: tag.value ? tag.value : tag.name,
          key: tag.key,
          selected: false,
          level: -1
        }
      }
      
      this.inputTags[tag.key] = item;
    })

    this.selectedTags.forEach(tag => {
      let item: ItemData = {
        value: tag.value ? tag.value : tag.name,
        key: tag.key,
        selected: true,
        level: tag.level != undefined ? tag.level : -1
      };
      if (this.tagsObject[tag.key]) {
        this.tagsObject[tag.key].selected = true;
      } else if (this.playerTypeObject[tag.key]) {
        this.playerTypeObject[tag.key].selected = true;
      } else {
        this.diffTags[tag.key] = item;
        this.diffExist = true;
      }
      this.checkedTags[tag.key] = item;
    })
  }

  dismiss = (event) => {
    event.stopPropagation();
    this.modalCtrl.dismiss();
  }

  preventDismiss = (event) => {
    event.stopPropagation();
  }

  ionViewWillEnter() {

  }

  ionViewWillLeave() {
    // delete this.selectedTags;
  }

  changeSelect = (key) => {
    if (this.tagsObject[key] != undefined) {
      this.tagsObject[key].selected = !this.tagsObject[key].selected;
    }

    if (this.checkedTags[key] != undefined) { // If this item is unchecked.
      if (this.tagsObject[key]) this.tagsObject[key].selected = false;
      if (this.diffTags[key]) this.diffTags[key].selected = false;
      delete this.checkedTags[key]
      this.updateCheckedTagsMemoryPointer();
      return;
    } else { // If this item is checked.
      if (this.tagsObject[key] != undefined) {
        this.tagsObject[key].selected = true;
        const existItem = Object.assign({}, this.tagsObject[key]);
        existItem.selected = true;
        this.checkedTags[key] = existItem;
      } else if (this.inputTags[key] != undefined) {
        const inputItem = Object.assign({}, this.inputTags[key]);
        inputItem.selected = true;
        this.checkedTags[key] = inputItem;
        this.diffTags[key] = inputItem;
        this.diffExist = true;
      }
      this.updateCheckedTagsMemoryPointer();
      if (Object.keys(this.diffTags).length == 0 ) {
        this.diffExist = false;
      }
    }
    

    // _.forEach(this.checkedTags, (item: ItemData) => {
    //   if (!this.tagsObject[item.key] || this.tagsObject[item.key].selected != true) {
    //     let diffItem = Object.assign({}, item);
    //     this.diffTags[item.key] = diffItem;
    //     this.diffExist = true;
    //   }
    // })
  }

  changeSelectPlayerType = (key) => {
    for (let loopKey in this.playerTypeObject) {
      if (loopKey != key) {
        this.playerTypeObject[loopKey].selected = false;
      }
      if (this.checkedTags[loopKey] != undefined) {
        delete this.checkedTags[loopKey];
      }
    }
    this.updateCheckedTagsMemoryPointer();
    if (this.playerTypeObject[key]) {
      this.playerTypeObject[key].selected = !this.playerTypeObject[key].selected
    }
    // if (this.checkedTags[key] != undefined) { // If this item is unchecked.
    //   delete this.checkedTags[key]
      
    //   return;
    // }
    if (this.playerTypeObject[key].selected == true) {
      const item = Object.assign({}, this.playerTypeObject[key]);
      item.selected = true;
      this.checkedTags[key] = item;
    }
    console.log("Tags === ", this.checkedTags, this.playerTypeObject);
  }

  updateCheckedTagsMemoryPointer = () => {
    this.checkedTags = Object.assign({}, this.checkedTags);
    if (Object.keys(this.checkedTags).length > 0) {
      this.checkExist = true;
    }
  }

  reset = () => {
    _.forEach(this.tagsObject, (element: ItemData) => {
      element.selected = false;
    });
    for (let key in this.checkedTags) delete this.checkedTags[key];
    for (let key in this.diffTags) delete this.diffTags[key];
    for (let key in this.searchResult) this.searchResult[key].selected = false;
    for (let key in this.playerTypeObject) this.playerTypeObject[key].selected = false;
    this.searchResult = Object.assign({}, this.searchResult);
    this.checkedTags = {};
    this.diffTags = {};
    this.diffExist = false;
    // this.searchExist = false;
    this.checkExist = false;
    this.applyFilter.emit({
      resultArray: [],
      playerType: ''
    });
    // this.playerTypeFilter.emit('')
  }

  changeSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    if (searchValue != '') {
      this.searchResult = {};
      for (let key in this.inputTags) {
        const value = this.inputTags[key].value.toLowerCase();

        if (value.indexOf(searchValue) > -1) {
          this.searchResult[key] = {
            value: this.inputTags[key].value,
            key: key,
            selected: false,
            level: this.inputTags[key].level
          }
          if (this.checkedTags[key] && this.checkedTags[key].selected == true) this.searchResult[key].selected = true;
          this.searchExist = true;
        }
      }
    } else {
      for (let key in this.searchResult) delete this.searchResult[key]
      // for (let key in this.diffTags) delete this.diffTags[key]
      this.searchResult = {}
      // this.diffTags = {}
      this.searchExist = false;
    }
  }

  apply = () => {
    let resultArray = []
    for (let key in this.checkedTags) {
      resultArray.push({
        key: key,
        value: this.checkedTags[key].value,
        level: this.checkedTags[key].level != undefined ? this.checkedTags[key].level : -1
      })
    }
    let playerType = '';
    if (this.playerOptionExist) {
      for (let key in this.playerTypeObject) {
        if (this.playerTypeObject[key].selected == true) {
          // this.playerTypeFilter.emit(key)
          playerType = key;
          break;
        } 
      }
    }
    console.log("Result Array === ", resultArray)
    this.applyFilter.emit({
      resultArray,
      playerType
    });
    this.modalCtrl.dismiss();
  }

}

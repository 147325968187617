import { Component, OnInit } from '@angular/core';
import { ActionSheetController, AlertController, IonNav, ModalController, NavParams } from '@ionic/angular';
import { AddPlayerComponent } from 'src/app/components/add-player/add-player.component';
import { AddScheduleComponent } from 'src/app/components/add-schedule/add-schedule.component';
import { BaseService } from 'src/app/services/base.service';
import { TeamService } from 'src/app/services/team.service';
import { CreateTeamSchedulePage } from '../create-team-schedule/create-team-schedule.page';
import { TeamCreationResult } from '../type';

@Component({
  selector: 'app-create-team-roster',
  templateUrl: './create-team-roster.page.html',
  styleUrls: ['./create-team-roster.page.scss'],
})
export class CreateTeamRosterPage implements OnInit {

  public teamId: number = null;
  public teamData: TeamCreationResult;
  public roster: Array<any> = [];
  public editing: boolean = false;
  public deleting: boolean = false;
  public photoUpdating: boolean = false;
  public selectedPlayer: number = null;
  public archivePlayers: Array<any> = [];
  public playerAddable: boolean = false;

  public subscribe = null;

  constructor(
    private nav: IonNav,
    private navParams: NavParams,
    private modalCtrl: ModalController,
    private teamService: TeamService,
    private atSheet: ActionSheetController,
    private baseService: BaseService,
    private alertCtrl: AlertController
  ) {
    this.teamData = this.navParams.get('prevData')
    console.log("Prev Team Data ==== ", this.teamData)
  }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.subscribe = this.teamService.teamsState.subscribe(state => {
      if (state) {
        this.teamService.getTeamData(this.teamData.pk, {p: 'home'}, true).then(result => {
          this.roster = result.roster;
          console.log("New Roster === ", result.roster);
        })
      }
    })
  }

  ionViewDidLeave() {
    // if (this.subscribe) {
    //   this.subscribe();
    // }
  }

  showActionSheet = () => {
    this.atSheet.create({
      header: 'Team Management',
      cssClass: 'my-action-sheet',
      buttons: [
        {
          text: 'Add a New Player',
          handler: () => {
            this.addPlayer();
          }
        },
        {
          text: 'Edit Player',
          handler: () => {
            this.editPlayer();
          }
        },
        {
          text: 'Delete Player',
          handler: () => {
            this.deletePlayer()
          }
        },
        // {
        //   text: 'Add Player From',
        //   handler: () => {
        //     this.addFrom()
        //   }
        // },
        // {
        //   text: 'Change Headshot',
        //   handler: () => {
        //     this.changeHeadshot()
        //   }
        // },
         {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
            this.editing = false;
            this.deleting = false;
            this.photoUpdating = false;
          }
        }
      ]
    }).then(atModalPhoto => {
      atModalPhoto.present();
    });
  }

  editPlayer = () => {
    if (this.roster.length == 0) return;
    this.editing = !this.editing;
  }

  deletePlayer = () => {
    if (this.roster.length == 0) return;
    this.deleting = !this.deleting;
  }

  addFrom = () => {
    
  }

  changeHeadshot = () => {
    
  }

  addPlayer = () => {
    this.modalCtrl.create({
      component: AddPlayerComponent,
      cssClass: 'second-add-modal',
      componentProps: {
        'teamId': this.teamData.pk
      }
    }).then(popOver => {
      popOver.present();
    })
  }

  goNext = () => {
    this.nav.push(CreateTeamSchedulePage, {prevData: this.teamData})
  }

  clickSkip = () => {
    this.modalCtrl.dismiss();
  }

  selectPlayer = (player) => {
    // this.selectedPlayer = player.pk;
    if (this.editing === true) {
      this.modalCtrl.create({
        component: AddPlayerComponent,
        cssClass: 'add-modal',
        componentProps: {
          'teamId': this.teamData.pk,
          'first_name': player.first_name,
          'last_name' : player.last_name,
          'day': player.day,
          'month': player.month,
          'year': player.year,
          'jersey_number': player.jersey_number,
          'position': player.position,
          'playerPK': player.pk,
          'hits': player.hits,
          'throws': player.arm
        }
      }).then(popOver => {
        popOver.present();
      }).finally(() => {
        this.editing = false;
      })
    }
    if (this.deleting === true) {
      this.alertCtrl
      .create({
        header: 'Remove Player.',
        message: 'Do you want to remove this player?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: (blah) => {
              console.log('Confirm Cancel: blah');
            },
          },
          {
            text: 'Remove',
            handler: () => {
              console.log('Confirm Okay');

              this.baseService.showLoading('');
              this.teamService.deletePlayerFromTeam(this.teamData.pk, player.pk).then(result => {

              }).finally(() => {
                
                this.baseService.dismissLoading();
              })
            },
          },
        ],
      })
      .then((alertModal) => {
        alertModal.present();
      }).finally(() => {
        this.deleting = false;
      });
      return;
    }
    if (this.photoUpdating === true) {
      
    }
  }

}

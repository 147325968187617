import { Component, NgZone } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { UserService } from './services/user.service';
import { NavigationEnd, NavigationExtras, Router, RouterEvent, NavigationStart } from '@angular/router';
import { NavigationService } from './services/navigation.service';
// var PushNotification = require('@havesource/cordova-plugin-push/types');
// import { } from '@havesource/cordova-plugin-push/types';
import { VideoUpload } from 'ionic-plugin-video-upload/video-upload/ngx';
import { Push, PushObject, PushOptions } from '@ionic-native/push/ngx';
import { Storage } from '@ionic/storage';
import { BaseService } from './services/base.service';
import { BranchIo, BranchIoProperties } from '@ionic-native/branch-io/ngx';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { LeagueService } from './services/league.service';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  public branchSession: any = null;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private navigationService: NavigationService,
    private storage: Storage,
    private push: Push,
    private baseService: BaseService,
    private branch: BranchIo,
    private zone: NgZone,
    private ga: GoogleAnalytics,
    private leagueService: LeagueService,
    private videoUpload: VideoUpload,
    private nativeStorage: NativeStorage
  ) {
    this.initializeApp();
  }

  // Add or remove the "dark" class based on if the media query matches
toggleDarkTheme(shouldAdd) {
  document.body.classList.toggle('dark', shouldAdd);
}

  initializeApp() {
    
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      // const preferLight = window.matchMedia('(prefers-color-scheme: dark)');

      // Listen for changes to the prefers-color-scheme media query
      // preferLight.addListener((mediaQuery) => this.toggleDarkTheme(mediaQuery.matches));
      this.toggleDarkTheme(false);
      this.storage.get('access_token').then(value => {
        if (value) {
          this.navigationService.deeplinkRouting(null);
        }
      })

      this.ga.startTrackerWithId('UA-38517229-6')
      .then(() => {
        console.log('Google analytics is ready now');
          // this.ga.trackView('test');
      })
      .catch(e => console.log('Error starting GoogleAnalytics', e));



        // this.videoUpload.subscribeCaptured().subscribe((captured) => {
        //   console.log("Captured ********** on Video Player ", JSON.stringify(captured))
        //   this.videoUpload.getCapturing().then(result => {
        //     console.log("Capturing Result ===== , ", result)
        //   })
        // })

        // const options: PushOptions = {
        //     android: {},
        //     ios: {
        //         alert: 'true',
        //         badge: true,
        //         sound: true
        //     },
        //     windows: {},
        //     browser: {
        //         pushServiceURL: ''
        //     }
        // }

        // const pushObject: PushObject = this.push.init(options);
        

        this.push.hasPermission()
        .then((res: any) => {
          if (res.isEnabled) {
          } else {
            this.storage.set('device_token', '');
          }
        });

        this.branch.setDebug(true);
        //  this.branch.disableTracking(true);
        this.handleBranch()
    });

    this.platform.resume.subscribe(() => {
      this.handleBranch();
    })

    
  }

  handleBranch = () => {
      this.branch.initSession().then(data => {
        console.log('Branch IO Data on Resume === ', JSON.stringify(data));
        if (data['+clicked_branch_link']) {
          console.log('Clicked Branch Io on Resume=== ', JSON.stringify(data));
          this.zone.run(() => {
            if (data.vid) {
              const videoId = data.vid;
              const key = 'http://pitchaware-web-prod.s3.amazonaws.com/media/playlists/pl_' +  data.key + '.json';
              const navigationExtras: NavigationExtras = {
                state: {
                  videoId: videoId,
                  videoKey: key,
                  shared: true
                }
              }
              // this.storage.set('deeplinkVideo', data.vid + data.key);
              this.router.navigateByUrl('video-player', navigationExtras);
            }
            if (data.event_id) {
              this.leagueService.getTournamentDataAndGo(data.event_id)
            }
          })
        }
      })
  }
}

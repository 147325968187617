import { Component, OnInit } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { CreateTeamSportPage } from '../create-team-sport/create-team-sport.page';
import { CreateTeamRolePage } from '../create-team-role/create-team-role.page';
import { TeamService } from 'src/app/services/team.service';
import { BaseService } from 'src/app/services/base.service';
import { CreateTeamData } from '../type';
import { CreateTeamRosterPage } from '../create-team-roster/create-team-roster.page';
import { CreateTeamDownloadPage } from '../create-team-download/create-team-download.page';

@Component({
  selector: 'app-manage-home',
  templateUrl: './manage-home.page.html',
  styleUrls: ['./manage-home.page.scss'],
})
export class ManageHomePage implements OnInit {

  public teamData: CreateTeamData = {}

  constructor(
    private modalController: ModalController,
    private nav: IonNav,
    private teamService: TeamService,
    private baseService: BaseService
  ) { }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.baseService.showLoading('Fetching...')
    this.teamService.getCreateTeamForm().then(result => {

    }).catch(error => {
      this.baseService.showAlertMessage('Error!', 'Create Team Form Fetching Failed!')
    }).finally(() => {
      this.baseService.dismissLoading()
    })
  }

  close = () => {
    this.modalController.dismiss()
  }

  goSports = () => {
    this.nav.push(CreateTeamRolePage, {prevData: this.teamData})
    // this.nav.push(CreateTeamDownloadPage, {prevData: this.teamData})
  }

}

import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';

export interface EntryContent {
    response: HttpResponse<any>;
    params: string;
    expiration: number;
}

@Injectable({
  providedIn: 'root'
})
export class RequestCachingService {
    public cache = new Map();

    public createKey(url: string, requestType: string) {
        const access_token: any = window.localStorage.getItem('access_token');
        let suffix = 'NoAccess';
        if (access_token) {
            suffix = access_token;
        }
        return `${url}|${requestType}|${suffix}`;
    }

    public get(request: HttpRequest<any>): HttpResponse<any> | undefined {
        const key = this.createKey(request.url, request.method);

        const cachedValue: EntryContent = this.cache.get(key);
        if (!cachedValue) {
            return undefined;
        }
        if (cachedValue.expiration >= Date.now() && cachedValue.params == request.params.toString()) {
            return cachedValue.response;
        }

        return undefined;
    }

    public clearKey(url: string, requestType: string) {
        this.clearPastKey(url, requestType);
        const key = this.createKey(url, requestType);
        this.cache.delete(key);
    }

    public clearPastKey(url: string, requestType: string) {
        for (const key of this.cache.keys()) {
            const keyString: string = key.toString();
            if (keyString.indexOf(`${url}|${requestType}`) > -1) {
                this.cache.delete(key);
                break;
            }
        }
    }

    public entryContent(response: HttpResponse<any>, expiration: number, httpParams: HttpParams): EntryContent {
        const params = httpParams.toString();
        return {
            response,
            params,
            expiration
        } as EntryContent;
    }

    public put(request: HttpRequest<any>, response: HttpResponse<any>, expirationTime: number, params: HttpParams): void {
        const key = this.createKey(request.url, request.method);
        const expiration = Date.now() + expirationTime * 1000;
        this.clearPastKey(request.url, request.method);
        this.cache.set(key, this.entryContent(response, expiration, params));
    }

    public clearAll() {
        this.cache.clear();
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AddPlayerComponent } from './add-player.component';

@NgModule({
  imports: [ CommonModule, FormsModule, IonicModule],
  declarations: [AddPlayerComponent],
  exports: [AddPlayerComponent]
})
export class AddPlayerComponentModule {}

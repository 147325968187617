import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ErrorFormat, BaseService } from './base.service';
import { MyTeamData, SeasonData, EventData } from './user.service';
import { MainGameData } from './player.service';
import { LOCAL_CACHE_DISABLE } from '../common/constant';
import { BehaviorSubject } from 'rxjs';
import { CreateTeamData } from '../pages/manage/type';


export interface FavoriteDetailData {
  items: Array<MyTeamData>;
}

export interface FavoriteResultData {
  status: string;
  detail: FavoriteDetailData;
}

export interface TeamBaseData {
  admin?: {
    level?: string;
    staff_invite_url?: string;
    scorekeeper_invite_url?: string;
  },
  base_distance: number;
  city: string | null;
  competition_display: string;
  competition_level: number;
  conference: string | null;
  favorite_team: boolean;
  followers: number;
  has_coaches: boolean;
  in_circle: boolean;
  is_college_team: boolean;
  is_private: boolean;
  linked_facility: any;
  logo_url: string;
  me_following: boolean;
  mound_distance: number;
  name: string;
  pk: number;
  requested_access: boolean;
  scoreboard_name: string;
  scout: boolean;
  scouting_url: string;
  seasons: Array<SeasonData>;
  sport: string;
  state_province: string | null | any;
}

export interface TeamHitterData {
  batting_average: string;
  doubles: number;
  fb_heart_swing_rate: string;
  first3_slugging: string;
  hits: number;
  homeruns: number;
  on_base: string;
  ops_total: string;
  overall_slugging: string;
  relative_slugging: string;
  strike_outs: number;
  swing_in_pay_rate: string;
  swing_miss_rate: string;
  swing_rate_nonquality_n2ks: string;
  swing_rate_quality_n2ks: string;
  total_atbats: number;
  triples: number;
  walks: number;
}

export interface TeamPitcherData {
  average_bb_velo: number;
  average_ch_velo: number;
  average_fb_velo: number;
  average_pitches_per_atbat: number;
  average_pitches_per_inning: number;
  batting_average: string;
  doubles: number;
  earned_runs_scored: number;
  fly_ball_percentage: string;
  full_era: string;
  full_whip: number;
  ground_ball_percentage: string;
  hits: number;
  homeruns: number;
  inning_pitched: number;
  line_drive_percentage: string;
  runs_scored: number;
  strike_outs: number;
  strike_outs_per_seven: number;
  strike_percentage: string;
  strike_percentage_first_pitch: string;
  swing_miss_rate: string;
  triples: number;
  walks: number;
  walks_per_seven: number;
}

export interface DexData {
  arm: string;
  hits: string;
}

export interface TeamStatsData {
  hitters: Array<{
    data: TeamHitterData,
    player_id: number;
    player_name: string;
    url: string;
  }>;
  pitchers: Array<{
    data: TeamPitcherData,
    player_id: number;
    player_name: string;
    url: string;
  }>;
}

export interface RosterData {
  arm: number;
  class_in_school: number;
  day: number;
  dex: DexData;
  first_name: string;
  full_name: string;
  height: number;
  hits: number;
  is_hitter: boolean;
  is_pitcher: boolean;
  jersey_number: number;
  last_name: string;
  month: number;
  pk: number;
  player_id: number;
  position: string;
  weight: number;
  year: number;
}

export interface TeamLeagueEventData {
  date_string: string;
  day_window: number;
  days_till: {
    start: number;
    finish: number;
  };
  divisions: any | null;
  end: string;
  free: boolean;
  full_view_access_window: boolean;
  league: number;
  league_name: string;
  location: any | null;
  logo_url: string;
  name: string;
  pk: number;
  rules_url: any | null;
  scoreboard_url: string;
  standings_file: string;
  standings_columns: Array<string>;
  start: string;
  stats_file: string;
  team_ids: Array<number>;
  teams_in_event_count: number;
  type: string;
  venues: Array<any>;
  contact_email?: string;
  event_type?: string,
  event_type_id?: number | string,
  game_hash?: string
}

export interface TeamHomeData {
  admin?: {
    level?: string;
    staff_invite_url?: string;
    scorekeeper_invite_url?: string;
  },
  event_games: {
    [index: number]: Array<EventData>;
  };
  events: Array<TeamLeagueEventData>;
  full_schedule: Array<EventData>;
  game_data: MainGameData;
  roster: Array<RosterData>;
  stats: TeamStatsData;
  team: TeamBaseData;
  season: number;
}

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  teamsState = new BehaviorSubject(false);
  teamAddState = new BehaviorSubject(false);

  constructor(
    private http: HttpClient,
    private baseService: BaseService
  ) {

  }

  public unFavoriteTeam(teamPK): Promise<FavoriteResultData|ErrorFormat> {
    let url = this.baseService.apiDomain + '/api/team/';
    url = url + teamPK + '/favorite/remove';

    return this.http.get<FavoriteResultData>(url).toPromise().then((result) => {
      console.log('UnFavorite Team Result ==== ', result);
      return result;
    }).catch(this.baseService.handleError);
  }

  public favoriteTeam(teamPK): Promise<FavoriteResultData|ErrorFormat> {
    let url = this.baseService.apiDomain + '/api/team/';
    url = url + teamPK + '/favorite/add';
    return this.http.get<FavoriteResultData>(url).toPromise().then(result => {
      console.log('Favorite Team Result ==== ', result);
      return result;
    }).catch(this.baseService.handleError);
  }

  public getTeamData(teamPK, params, disableCache = false): Promise<any> {
    if (!teamPK) return;
    let url = this.baseService.apiDomain + '/api/team/';
    url = url + teamPK;
    console.log('Team Get URL === ', url);
    let headers = new HttpHeaders();
    if (disableCache) {
      headers = headers.set(LOCAL_CACHE_DISABLE, '1');
    }
    if (params != null) {
      let reqParams = new HttpParams();
      Object.keys(params).forEach(key => {
        reqParams = reqParams.set(key, params[key]);
      });
      reqParams.set('datetime', Date.now().toString())
      return this.http.get<any>(url, {params: reqParams, headers}).toPromise().then((result) => {
        return result;
      });
    } else {
      return this.http.get<any>(url, { headers }).toPromise().then((result) => {
        return result;
      });
    }
  }

  public getAddTeamForm(): Promise<any> {
    const url = this.baseService.apiDomain + '/api/signup/team';
    return this.http.get<any>(url).toPromise().then((result) => {
      console.log('Get Add Team Form Result === ', result);
      return result;
    });
  }

  public addTeam(teamData: any): Promise<any> {
    let url = this.baseService.apiDomain + '/api/signup/team';
    let bodyArray: Array<any> = [];
    for (let key of Object.keys(teamData)) {
      bodyArray.push(encodeURIComponent(key) + '=' + encodeURIComponent(teamData[key])); 
    }
    const body = bodyArray.join('&');
    let headers = this.baseService.Headers(true, false);
    headers = headers.set('Content-type', 'application/x-www-form-urlencoded');
    return this.http.post<any>(url, body, { headers }).toPromise().then((result) => {
      console.log('Add team result  === ', result);
      this.teamAddState.next(true);
      return result;
    }).catch(this.baseService.handleError);
  }

  public getPlayerAddForm(teamId): Promise<any> {
    const url = this.baseService.apiDomain + '/api/team/' + teamId + '/player/create';
    return this.http.get<any>(url).toPromise().then((result) => {
      console.log('Player Create Form Result ==== ', result);
      return result;
    }).catch(this.baseService.handleError);
  }

  public addPlayerToTeam(teamId, data) : Promise<any> {
    const url = this.baseService.apiDomain + '/api/team/' + teamId + '/player/create';
    let bodyArray: Array<any> = [];
    for (let key of Object.keys(data)) {
      bodyArray.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key])); 
    }
    const body = bodyArray.join('&');
    let headers = this.baseService.Headers(true, false);
    headers = headers.set('Content-type', 'application/x-www-form-urlencoded');
    return this.http.post<any>(url, body, { headers }).toPromise().then((result) => {
      console.log('Player Create Result ==== ', result);
      this.teamsState.next(true);
      return result;
    }).catch(this.baseService.handleError);
  }

  public editPlayerToTeam(teamId, data, playerPK) : Promise<any> {
    const url = this.baseService.apiDomain + '/api/team/' + teamId + '/player/edit/' + playerPK;
    let bodyArray: Array<any> = [];
    for (let key of Object.keys(data)) {
      bodyArray.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key])); 
    }
    const body = bodyArray.join('&');
    let headers = this.baseService.Headers(true, false);
    headers = headers.set(LOCAL_CACHE_DISABLE, '1');
    headers = headers.set('Content-type', 'application/x-www-form-urlencoded');
    return this.http.post<any>(url, body, { headers }).toPromise().then((result) => {
      console.log('Player Change Result ==== ', result);
      this.teamsState.next(true);
      return result;
    }).catch(this.baseService.handleError);
  }

  public deletePlayerFromTeam(teamId, playerPK) : Promise<any> {
    const url = this.baseService.apiDomain+ '/api/team/' + teamId + '/player/drop/' + playerPK;
    const body = encodeURIComponent('delete') + '=' + encodeURIComponent('1');
    let headers = new HttpHeaders()
    headers = headers.set('Content-type', 'application/x-www-form-urlencoded');
    return this.http.post<any>(url, body, { headers }).toPromise().then((result) => {
      console.log('Player Delete Result ==== ', result);
      this.teamsState.next(true);
      return result;
    }).catch(this.baseService.handleError);

  }

  public getScheduleFormData(teamId) : Promise<any> {
    const url = this.baseService.apiDomain + '/api/scorekeeper/' + teamId + '/add';
    return this.http.get<any>(url).toPromise().then((result) => {
      console.log('Team Schedule Form Result ==== ', result);
      return result;
    }).catch(this.baseService.handleError);
  }

  public addTeamSchedule(teamId, data) : Promise<any> {
    const url = this.baseService.apiDomain + '/api/scorekeeper/' + teamId + '/add';
    let bodyArray: Array<any> = [];
    for (let key of Object.keys(data)) {
      bodyArray.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key])); 
    }
    const body = bodyArray.join('&');
    let headers = this.baseService.Headers(true, false);
    headers = headers.set('Content-type', 'application/x-www-form-urlencoded');
    return this.http.post<any>(url, body, { headers }).toPromise().then((result) => {
      console.log('Player Create Result ==== ', result);
      this.teamsState.next(true);
      return result;
    }).catch(this.baseService.handleError);
  }

  public editTeamSchedule(gameId, data): Promise<any> {
    const url = this.baseService.apiDomain + '/api/scorekeeper/game/' + gameId + '/edit';
    let bodyArray: Array<any> = [];
    for (let key of Object.keys(data)) {
      bodyArray.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key])); 
    }
    const body = bodyArray.join('&');
    let headers = this.baseService.Headers(true, false);
    headers = headers.set('Content-type', 'application/x-www-form-urlencoded');
    return this.http.post<any>(url, body, { headers }).toPromise().then((result) => {
      console.log('Edit Team Schedule Result ==== ', result);
      this.teamsState.next(true);
      return result;
    }).catch(this.baseService.handleError);
  }

  public getTeamAdminList(teamId) : Promise<any> {
    const url = this.baseService.apiDomain + '/api/team/' + teamId + '/coach';
    let headers = this.baseService.Headers(true, false);
    headers = headers.set(LOCAL_CACHE_DISABLE, '1');
    return this.http.get<any>(url, { headers }).toPromise().then((result) => {
      console.log('Team Admin List Result ==== ', result);
      return result;
    }).catch(this.baseService.handleError);
  }

  public getTeamAddForm(): Promise<any> {
    const url = this.baseService.apiDomain + '/api/signup/team';
    return this.http.get<any>(url).toPromise().then((result) => {
      console.log('Team Create Form Result ==== ', result);
      return result;
    }).catch(this.baseService.handleError);
  }

  public deleteCoach(teamId, coachPK): Promise<any> {
    const url = this.baseService.apiDomain + '/api/team/' + teamId + '/coach/drop/' + coachPK;
    const body = encodeURIComponent('delete') + '=' + encodeURIComponent('1');
    let headers = new HttpHeaders()
    headers = headers.set('Content-type', 'application/x-www-form-urlencoded');
    return this.http.post<any>(url, body, { headers }).toPromise().then((result) => {
      console.log('Coach Delete Result ==== ', result);
      this.teamsState.next(true);
      return result;
    }).catch(this.baseService.handleError);
  }

  public deleteManager(teamId, managerPK): Promise<any> {
    const url = this.baseService.apiDomain + '/api/team/' + teamId + '/coach/drop-sa/' + managerPK;
    const body = encodeURIComponent('delete') + '=' + encodeURIComponent('1');
    let headers = new HttpHeaders()
    headers = headers.set('Content-type', 'application/x-www-form-urlencoded');
    return this.http.post<any>(url, body, { headers }).toPromise().then((result) => {
      console.log('Manager Delete Result ==== ', result);
      this.teamsState.next(true);
      return result;
    }).catch(this.baseService.handleError);
  }

  public getFavoriteUsers(teamId): Promise<any> {
    const url = this.baseService.apiDomain + '/api/team/' + teamId + '/favorite/users';
    return this.http.get<any>(url).toPromise().then((result) => {
      console.log('Team Favorite Users Result ==== ', result);
      return result;
    }).catch(this.baseService.handleError);
  }

  public addManager(teamId, userPK): Promise<any> {
    const url = this.baseService.apiDomain + '/api/team/' + teamId + '/coach/add-sa/' + userPK;
    const body = encodeURIComponent('add') + '=' + encodeURIComponent('1');
    let headers = new HttpHeaders()
    headers = headers.set('Content-type', 'application/x-www-form-urlencoded');
    return this.http.post<any>(url, body, { headers }).toPromise().then((result) => {
      console.log('Manager Add Result ==== ', result);
      this.teamsState.next(true);
      return result;
    }).catch(this.baseService.handleError);
  }

  public getArchivedPlayers(teamId): Promise<any> {
    const url = this.baseService.apiDomain + '/api/team/' + teamId + '/player/add/o';
    return this.http.get<any>(url).toPromise().then((result) => {
      console.log('Get Archived Players Result ==== ', result);
      return result;
    }).catch(this.baseService.handleError);
  }

  public addToOwner(teamId, coachPK): Promise<any> {
    const url = this.baseService.apiDomain + '/api/team/' + teamId + '/coach/owner/' + coachPK;
    const body = encodeURIComponent('owner') + '=' + encodeURIComponent('1');
    let headers = new HttpHeaders()
    headers = headers.set('Content-type', 'application/x-www-form-urlencoded');
    return this.http.post<any>(url, body, { headers }).toPromise().then((result) => {
      console.log('Add coach to owner Result ==== ', result);
      this.teamsState.next(true);
      return result;
    }).catch(this.baseService.handleError);
  }

  public getStaff(teamId) : Promise<any> {
    const url = this.baseService.apiDomain + '/api/facility/' + teamId;
    const params = {p: 'staff'};
    return this.http.get<any>(url, {params}).toPromise().then((result) => {
      console.log('Get Staff Result ==== ', result);
      return result;
    }).catch(this.baseService.handleError);
  }

  public getTeams(): Promise<any> {
    // const url = this.baseService.apiDomain + '/api/facility/1';
    const url = this.baseService.apiDomain + '/api/team/';
    // const params = {p: 'teams'};
    // let headers = this.baseService.Headers(true, false);
    // headers = headers.set(LOCAL_CACHE_DISABLE, '1');
    return this.http.get<any>(url).toPromise().then((result) => {
      console.log('Get Teams Result ==== ', result);
      return result;
    }).catch(this.baseService.handleError);
  }

  public getCreateTeamForm(): Promise<any> {
    const url = this.baseService.apiDomain + '/api/team/create';
    return this.http.get<any>(url).toPromise().then((result) => {
      console.log('Get Team Creation Form Result ==== ', result);
      return result;
    }).catch(this.baseService.handleError);
  }

  public postCreateTeam(teamData: CreateTeamData): Promise<any> {
    const url = this.baseService.apiDomain + '/api/team/create';
    let bodyArray: Array<any> = [];
    for (let key of Object.keys(teamData)) {
      bodyArray.push(encodeURIComponent(key) + '=' + encodeURIComponent(teamData[key])); 
    }
    const body = bodyArray.join('&');
    let headers = this.baseService.Headers(true, false);
    headers = headers.set('Content-type', 'application/x-www-form-urlencoded');
    return this.http.post<any>(url, teamData).toPromise().then((result) => {
      console.log("Team Creation Post Result === ", result);
      this.teamAddState.next(true);
      return result;
    }).catch(this.baseService.handleError)
  }

  public teamSearchForEvent(eventPK: number, search: string): Promise<any> {
    const url = this.baseService.apiDomain + '/api/league/' + eventPK + '/search/team/';
    let params = new HttpParams().set('search', search);
    return this.http.get<any>(url, {params}).toPromise().then((result) => {
      console.log("Find Team For Event result ==== ", result);
      return result;
    }).catch(this.baseService.handleError)
  }
}

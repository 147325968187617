import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { TagComponentComponent } from './tag-component.component';
import { SelectItemComponentModule } from '../select-item/select-item.module';
import { ArrayConvertPipeModule } from '../../pipes/array-convert.module';

@NgModule({
  imports: [ CommonModule, FormsModule, IonicModule, 
      SelectItemComponentModule, ArrayConvertPipeModule,
      ],
  declarations: [TagComponentComponent],
  exports: [TagComponentComponent]
})
export class TagComponentComponentModule {}

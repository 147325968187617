import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'app',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./pages/landing/landing.module').then( m => m.LandingPageModule)
  },
  // {
  //   path: 'following',
  //   loadChildren: () => import('./pages/following/following.module').then( m => m.FollowingPageModule)
  // },
  // {
  //   path: 'schedule',
  //   loadChildren: () => import('./pages/schedule/schedule.module').then( m => m.SchedulePageModule)
  // },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module').then( m => m.WelcomePageModule)
  },
  // {
  //   path: 'team',
  //   loadChildren: () => import('./pages/team/team.module').then( m => m.TeamPageModule)
  // },
  // {
  //   path: 'player',
  //   loadChildren: () => import('./pages/player/player.module').then( m => m.PlayerPageModule)
  // },
  // {
  //   path: 'game',
  //   loadChildren: () => import('./pages/game/game.module').then( m => m.GamePageModule)
  // },
  // {
  //   path: 'tournament',
  //   loadChildren: () => import('./pages/tournament/tournament.module').then( m => m.TournamentPageModule)
  // },
  // {
  //   path: 'team-data',
  //   loadChildren: () => import('./pages/team-data/team-data.module').then( m => m.TeamDataPageModule)
  // },
  // {
  //   path: 'team-schedule',
  //   loadChildren: () => import('./pages/team-schedule/team-schedule.module').then( m => m.TeamSchedulePageModule)
  // },
  // {
  //   path: 'team-roster',
  //   loadChildren: () => import('./pages/team-roster/team-roster.module').then( m => m.TeamRosterPageModule)
  // },
  // {
  //   path: 'tournaments',
  //   loadChildren: () => import('./pages/tournaments/tournaments.module').then( m => m.TournamentsPageModule)
  // },
  // {
  //   path: 'tournament-live',
  //   loadChildren: () => import('./pages/tournament-live/tournament-live.module').then( m => m.TournamentLivePageModule)
  // },
  // {
  //   path: 'tournament-schedule',
  //   loadChildren: () => import('./pages/tournament-schedule/tournament-schedule.module').then( m => m.TournamentSchedulePageModule)
  // },
  // {
  //   path: 'game-scoreboard',
  //   loadChildren: () => import('./pages/game-scoreboard/game-scoreboard.module').then( m => m.GameScoreboardPageModule)
  // },
  // {
  //   path: 'player-game',
  //   loadChildren: () => import('./pages/player-game/player-game.module').then( m => m.PlayerGamePageModule)
  // },
  {
    path: 'video-player',
    loadChildren: () => import('./pages/video-player/video-player.module').then( m => m.VideoPlayerPageModule)
  },
  {
    path: 'livestream-player',
    loadChildren: () => import('./pages/livestream-player/livestream-player.module').then( m => m.LivestreamPlayerPageModule)
  },
  // {
  //   path: 'home',
  //   loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  // },
  {
    path: 'landing',
    loadChildren: () => import('./pages/landing/landing.module').then( m => m.LandingPageModule)
  },
  {
    path: 'new-signup',
    loadChildren: () => import('./pages/new-signup/new-signup.module').then( m => m.NewSignupPageModule)
  },
  {
    path: 'new-login',
    loadChildren: () => import('./pages/new-login/new-login.module').then( m => m.NewLoginPageModule)
  },
  {
    path: 'signup-link',
    loadChildren: () => import('./pages/signup-link/signup-link.module').then( m => m.SignupLinkPageModule)
  },
  {
    path: 'landing-link',
    loadChildren: () => import('./pages/landing-link/landing-link.module').then( m => m.LandingLinkPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
  
  // {
  //   path: 'notifications',
  //   loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule)
  // },
  // {
  //   path: 'notification-setting',
  //   loadChildren: () => import('./pages/notification-setting/notification-setting.module').then( m => m.NotificationSettingPageModule)
  // },
  // {
  //   path: 'add-team',
  //   loadChildren: () => import('./pages/add-team/add-team.module').then( m => m.AddTeamPageModule)
  // },
  // {
  //   path: 'add-player',
  //   loadChildren: () => import('./pages/add-player/add-player.module').then( m => m.AddPlayerPageModule)
  // },
  // {
  //   path: 'team-admin',
  //   loadChildren: () => import('./pages/team-admin/team-admin.module').then( m => m.TeamAdminPageModule)
  // },
  {
    path: 'purchase',
    loadChildren: () => import('./pages/purchase/purchase.module').then( m => m.PurchasePageModule)
  },
  {
    path: 'find-events',
    loadChildren: () => import('./pages/find-events/find-events.module').then( m => m.FindEventsPageModule)
  },
  {
    path: 'manage-home',
    loadChildren: () => import('./pages/manage/manage-home/manage-home.module').then( m => m.ManageHomePageModule)
  },
  {
    path: 'create-team-sport',
    loadChildren: () => import('./pages/manage/create-team-sport/create-team-sport.module').then( m => m.CreateTeamSportPageModule)
  },
  {
    path: 'create-team-role',
    loadChildren: () => import('./pages/manage/create-team-role/create-team-role.module').then( m => m.CreateTeamRolePageModule)
  },
  {
    path: 'create-team-type',
    loadChildren: () => import('./pages/manage/create-team-type/create-team-type.module').then( m => m.CreateTeamTypePageModule)
  },
  {
    path: 'create-team-location',
    loadChildren: () => import('./pages/manage/create-team-location/create-team-location.module').then( m => m.CreateTeamLocationPageModule)
  },
  {
    path: 'create-team-detail',
    loadChildren: () => import('./pages/manage/create-team-detail/create-team-detail.module').then( m => m.CreateTeamDetailPageModule)
  },
  {
    path: 'create-team-privacy',
    loadChildren: () => import('./pages/manage/create-team-privacy/create-team-privacy.module').then( m => m.CreateTeamPrivacyPageModule)
  },
  {
    path: 'create-team-finish',
    loadChildren: () => import('./pages/manage/create-team-finish/create-team-finish.module').then( m => m.CreateTeamFinishPageModule)
  },
  {
    path: 'add-schedule',
    loadChildren: () => import('./pages/add-schedule/add-schedule.module').then( m => m.AddSchedulePageModule)
  },
  {
    path: 'bio-sign',
    loadChildren: () => import('./pages/bio-sign/bio-sign.module').then( m => m.BioSignPageModule)
  },
  {
    path: 'event-notification',
    loadChildren: () => import('./pages/event-notification/event-notification.module').then( m => m.EventNotificationPageModule)
  },
  {
    path: 'one-time-purchase',
    loadChildren: () => import('./pages/one-time-purchase/one-time-purchase.module').then( m => m.OneTimePurchasePageModule)
  },
  // {
  //   path: 'team-admin-new',
  //   loadChildren: () => import('./pages/team-admin-new/team-admin-new.module').then( m => m.TeamAdminNewPageModule)
  // },
  // {
  //   path: 'teams-admin',
  //   loadChildren: () => import('./pages/teams-admin/teams-admin.module').then( m => m.TeamsAdminPageModule)
  // },
  // {
  //   path: 'profile',
  //   loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  // },
  // {
  //   path: 'game-cast',
  //   loadChildren: () => import('./pages/game-cast/game-cast.module').then( m => m.GameCastPageModule)
  // }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeCheck'
})
export class TimeCheckPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    const tStamp = Date.parse(value);
    const currentStamp = Date.now();

    let difference = currentStamp - tStamp;

    if (difference < 0) { difference = 0; }

    const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
    difference -= daysDifference * 1000 * 60 * 60 * 24;

    const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
    difference -= hoursDifference * 1000 * 60 * 60;

    const minutesDifference = Math.floor(difference / 1000 / 60);
    difference -= minutesDifference * 1000 * 60;

    const secondsDifference = Math.floor(difference / 1000);

    const weekDifference = Math.floor(daysDifference / 7);
    const monthDifference = Math.floor(daysDifference / 30);
    const yearDifference = Math.floor(monthDifference / 12);

    if (yearDifference > 0 && yearDifference < 2) { return '1 year ago'; }
    if (yearDifference > 1) { return yearDifference + ' years ago'; }
    if (monthDifference > 0 && monthDifference < 2) { return '1 month ago'; }
    if (monthDifference > 1) { return monthDifference + ' months ago'; }
    if (weekDifference > 0 && weekDifference < 2) { return '1 week ago'; }
    if (weekDifference > 1) { return weekDifference + ' weeks ago'; }
    if (daysDifference > 0 && daysDifference < 2) { return '1 day ago'; }
    if (daysDifference > 1) { return daysDifference + ' days ago'; }
    if (hoursDifference == 1) { return 'an hour ago'; }
    if (hoursDifference > 1) { return hoursDifference + ' hours ago'; }
    if (minutesDifference > 0 && minutesDifference < 2) { return 'a minute ago'; }
    if (minutesDifference > 1) { return minutesDifference + ' minutes ago'; }

    return 'a few seconds ago';
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ErrorFormat, BaseService } from './base.service';
import { TeamLeagueEventData } from './team.service';
import { LOCAL_CACHE_DISABLE } from '../common/constant';
import { NavigationExtras, Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { NavController, Platform } from '@ionic/angular';

export interface LeagueData {
  hash: string;
  is_active: boolean;
  is_public: boolean;
  name: string;
  pk: number;
  scoreboard_name: string;
  logo_url: string;
}

export interface TournamentShortcut {
  filter: {
    venue_name?: string,
    venue__name?: string,
    league?: number
  },
  api?: {
    venue_id?: number
  }
  logo_url: string
}

export interface TournamentsData {
  leagues: Array<LeagueData>;
  events: Array<TeamLeagueEventData>;
  recent_viewed: Array<number | string>
  practices?: Array<TeamLeagueEventData>;
  shortcuts?: Array<TournamentShortcut>;
}

export interface TournamentInfoData {
  alerts: Array<any>;
  news: Array<any>;
}

export interface TournamentGameData {
  away: string;
  away_errors: number;
  away_hits: number;
  away_id: number;
  away_score: number;
  date: string;
  finished: boolean;
  game_id: string;
  game_time: string;
  game_url: string;
  game_url_iframe: string;
  home: string;
  home_errors: number;
  home_hits: number;
  home_id: number;
  home_score: number;
  inning: number;
  last_timestamp: number | any;
  location: string;
  outs: number;
  result: number;
  stamp: number;
  status_tab: string;
  top_of_inning: boolean;
  venue: string | null;
  division_id: number
}

export interface TournamentGames {
  games: Array<TournamentGameData>;
  timestamp?: number;
  alert?: string;
}

export interface TournamentStandingTeam {
  name: string;
  results: {
    w: number;
    l: number;
    t: number;
    p: number;
  };
  scoreboard_name: string;
  tid: number;
}

export interface TournamentStandingData {
  key: number;
  level: string;
  parent: string;
  teams: Array<TournamentStandingTeam>;
  parent_level?: number;
}

export interface LeaderPlayerData {
  full_name: string;
  id: number;
  metrics: {
    hrs: number;
    baa: string;
    bbs: number;
    double: number;
    hits: number;
    rbis: number;
    sbs: number;
    triple: number;
    xbs: number;
  };
  ranks: {
    baa: number;
    bbs: number;
    double: number;
    hits: number;
    hrs: number;
    rbis: number;
    sbs: number;
    triple: number;
    xbs: number;
  };
  team_id: number;
  team_name: string;
  uni: number;
}

export interface LeaderTeamData {
  metrics: {
    baa: string;
    bbs: number;
    double: number;
    hits: number;
    hrs: number;
    rbis: number;
    sbs: number;
    triple: number;
    xbs: number;
  };
  ranks: {
    baa: number;
    bbs: number;
    double: number;
    hits: number;
    hrs: number;
    rbis: number;
    sbs: number;
    triple: number;
    xbs: number;
  };
  team_id: number;
  team_name: string;
}

export interface LeadersData {
  hitting: {
    keys: Array<any>;
    overall: {
      rankings: {
        players: Array<LeaderPlayerData>;
        teams: Array<LeaderTeamData>;
      };
    };
    today: {
      date: string;
      rankings: {
        players: Array<LeaderPlayerData>;
        teams: Array<LeaderTeamData>;
      };
    }
  };
  last_pitch_id: number;
  last_updated: string;
  divisions: Array<{
    id: number,
    name: string
  }>
  pitching: {
    today: {
      date: string;
      rankings: {
        players: Array<LeaderPlayerData>;
        teams: Array<LeaderTeamData>;
      };
    };
    keys: Array<any>;
    overall: {
      rankings: {
        players: Array<LeaderPlayerData>;
        teams: Array<LeaderTeamData>;
      };
    };
  };
}

export interface LiveStreamData {
  away: string;
  away_id: number;
  away_score: number;
  game_id: string;
  home: string;
  home_id: number;
  home_score: number;
  thumbnail: string;
}

export interface FeedData {
  actor: string;
  bumped: boolean;
  foreign_id: string;
  header: {
    id: number;
    name: string;
    type: string;
  };
  id: string;
  image: string;
  league: {
    event_id: number;
    event_name: string;
    league_id: number;
    league_name: string;
  };
  likes: number;
  link: string;
  my_team: string;
  object: string;
  origin: string | null;
  share_url: string;
  sport_type: number;
  target: string;
  time: string;
  tweet: string;
  versus: {
    id: number;
    name: string;
  };
  chart_key: string;
  what_type: string;
  who: string;
  who_id: number;
  who_type: string;
}

export interface TabActivityData {
  livestreams: Array<LiveStreamData>;
  feed: Array<FeedData>;
}

@Injectable({
  providedIn: 'root'
})
export class LeagueService {

  constructor(
    private http: HttpClient,
    private baseService: BaseService,
    private router: Router,
    private storage: Storage,
    private navCtrl: NavController,
    private platform: Platform
  ) { }

  public getTournaments(temp_venue=''): Promise <TournamentsData | ErrorFormat> {
    const url = this.baseService.apiDomain + '/api/league/?timestamp' + Date.now();
    let params = new HttpParams();
    if (temp_venue !== '') {
      params = params.set('venue_id', temp_venue);
    }
    return this.http.get<TournamentsData>(url, { params }).toPromise().then((result) => {
      console.log('Get Tournaments Result ==== ', result);
      return result;
    }).catch(this.baseService.handleError);
  }

  public getTournamentInfo(tId): Promise<any> {
    let url = this.baseService.apiDomain + '/api/leagueevent/';
    url = url + tId + '/info';
    return this.http.get<any>(url).toPromise().then((result) => {
      console.log('Get Tournament Info Result ==== ', result);
      return result;
    }).catch(this.baseService.handleError);
  }

  public getTournamentDataAndGo(tId) {
    this.storage.get('profile').then(result => {
      if (result) {
        this.router.navigateByUrl('app/tournaments');
        this.getTournament(tId).then((result) => {
          console.log("Tournament Data from api ==== ", result);
          const navigationExtras: NavigationExtras = {
            state: {
              tournamentId: tId,
              tournament: result.event,
            }
          }
          this.router.navigateByUrl('app/tournaments/tournament-schedule', navigationExtras);
        }).catch(err => {
          this.router.navigateByUrl('app/tournaments');
        })    
      } else {
        this.navCtrl.navigateRoot('new-login');
      }
    }).catch(() => {
      this.navCtrl.navigateRoot('new-login');
    })
  }

  public getTournament(tId): Promise<any> {
    let url = this.baseService.apiDomain + '/api/leagueevent/';
    url = url + tId + '/basic';
    return this.http.get<any>(url).toPromise().then((result) => {
      console.log("Tournament Data from api ==== ", result);
      return result;
    }).catch(this.baseService.handleError);
  }

  public checkTournamentNotification(params): Promise<any> {
    let url = this.baseService.apiDomain + '/api/event-notifications';
    return this.http.get<any>(url, {params}).toPromise().then((result) => {
      console.log('check Tournament Notification Result ==== ', result);
      return result;
    }).catch(this.baseService.handleError);
  }

  public getTournamentGamesFromFile(tournamentPK): Promise<TournamentGames| ErrorFormat> {
    let fileURL = '';
    const apiDomain = this.baseService.apiDomain.toLowerCase();
    if (apiDomain.includes('dev')) {
      fileURL = environment.devMediaUrl + 'league/event-' + tournamentPK + '/schedule.json?stamp=' + Date.now();
    } else {
      fileURL = environment.mediaUrl + 'league/event-' + tournamentPK + '/schedule.json?stamp=' + Date.now();
    }
    const params = new HttpParams().set('stamp', new Date().getTime().toString());
    return this.http.get<TournamentGames>(fileURL, {params}).toPromise().then((result) => {
      console.log('Tournaments Games === ', result);
      return result;
    }).catch(this.baseService.handleError);
  }

  public getTournamentGamesFromServer(tournamentPK): Promise<TournamentGames| ErrorFormat> {
    let url = this.baseService.apiDomain + '/api/leagueevent/';
    url = url + tournamentPK + '/games';
    return this.http.get<TournamentGames>(url).toPromise().then((result) => {
      console.log('Get Tournament Games From Server Result ==== ', result);
      return result;
    }).catch(this.baseService.handleError);
  }

  public getJSONFile(url, disableCache = false): Promise<any | ErrorFormat> {
    const params = new HttpParams().set('stamp', new Date().getTime().toString());

    let headers = new HttpHeaders().set('Access-Control-Allow-Origin', '*');
    if (disableCache == true) {
      headers = headers.set(LOCAL_CACHE_DISABLE, '1');
    }
    return this.http.get<any>(url, {params, headers}).toPromise().then((result) => {
      console.log('Get Standings Result ==== ', result);
      return result;
    }).catch(this.baseService.handleError);
  }

  public getTabActivity(eventPK): Promise<TabActivityData | ErrorFormat> {
    const url = this.baseService.apiDomain + '/api/leagueevent/' + eventPK;
    const params = new HttpParams().set('tab', 'activity');
    return this.http.get<TabActivityData>(url, {params}).toPromise().then((result) => {
      console.log('Get TabActivity Result ==== ', result);
      return result;
    }).catch(this.baseService.handleError);
  }

  public toggleNotification(tournamentId, toggleValue): Promise<any> {
    const url = this.baseService.apiDomain + '/api/event-notifications';
    let body = "";
    if (this.platform.is('ios')) {
      body = encodeURIComponent('event_id') + '=' + encodeURIComponent(tournamentId) + '&' + encodeURIComponent('val') + '=' + encodeURIComponent(toggleValue) + '&' + encodeURIComponent('os') + '=' + encodeURIComponent('ios') +
      '&' + encodeURIComponent('application_id') + '=' + encodeURIComponent('pa');
    } else {
      body = encodeURIComponent('event_id') + '=' + encodeURIComponent(tournamentId) + '&' + encodeURIComponent('val') + '=' + encodeURIComponent(toggleValue) + '&' + encodeURIComponent('os') + '=' + encodeURIComponent('android') +
    '&' + encodeURIComponent('application_id') + '=' + encodeURIComponent('pa');
    } 
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.post<any>(url, body.toString(), {
      headers: headers
    }).toPromise().then(result => {
      console.log("Event Notification Toggle Result === ", JSON.stringify(result));
      return result;
    });
  }

  public checkNotification(tournamentId): Promise<any> {
    const url = this.baseService.apiDomain + '/api/event-notifications';
    
    // let body = encodeURIComponent('event_id') + '=' + encodeURIComponent(tournamentId) + '&' + encodeURIComponent('token') + '=' + 
    // encodeURIComponent(deviceToken) + '&' + encodeURIComponent('at') + '=' + encodeURIComponent('a');
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.get<any>(url, {
      headers: headers,
      params: {
        event_id: tournamentId,
        application_id: 'pa',
        os: this.platform.is('ios') ? 'ios' : 'android'
      }
    }).toPromise().then(result => {
      console.log("Event Notification Check Result === ", JSON.stringify(result));
      return result;
    });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LOCAL_CACHE_DISABLE } from '../common/constant';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { NavigationEnd, NavigationExtras, NavigationStart, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { GlobalEmitterService, GlobalEvents } from './global-emitter.service';
import { UpgradeAccountComponent } from '../components/upgrade-account/upgrade-account.component';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { OneTimePurchasePage } from '../pages/one-time-purchase/one-time-purchase.page';
import { BehaviorSubject } from 'rxjs';


export interface ErrorFormat {
  status: number;
  reason?: string | null;
  description?: string | null;
}

export interface AppType {
  name: string,
  disabled: boolean,
  area: string,
  value: number,
}

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  public loader: any = null;
  public loading: boolean = false;
  public alertShowen: boolean = false;
  public currentTabUri: string = 'app/main';
  public currentURL: string = '';
  public previousURL: string = '';
  public gameCastInterval = null;
  public gameBoxInterval = null;
  public gamePBPInterval = null;
  public tournamentScheduleInterval = null;
  public apiDomain = ''; //'https://dev.birdiefire.com', 'https://www.pitchaware.com'
  public sportTypeState = new BehaviorSubject(false);
  public venueAppTypeState = new BehaviorSubject(false);
  public checkPrime: boolean = false;
  public appTypes: Array<AppType> = [
    {
      name: 'Grand Park',
      area: 'venue_id',
      value: 35,
      disabled: false,
    },
    {
      name: 'PitchAware Baseball',
      area: 'sport_type',
      value: 1,
      disabled: false,
    },
    {
      name: 'PitchAware Softball',
      area: 'sport_type',
      value: 2,
      disabled: false
    },
    {
      name: 'KickAware',
      area: 'sport_type',
      value: 10,
      disabled: true
    },
    {
      name: 'LaxAware',
      area: 'sport_type',
      value: 20,
      disabled: true
    }
  ]
  public currentAppType: AppType = {
    name: 'PitchAware Baseball',
    area: 'sport_type',
    value: 1,
    disabled: false,
  }
  public tempVenueValue: number = null;

  constructor(
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private modalCtrl: ModalController,
    private router: Router,
    private platform: Platform,
    private storage: Storage,
    private globalService: GlobalEmitterService,
    private iab: InAppBrowser,
    private http: HttpClient,
  ) {
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      console.log('App Router subscribe === ', event.url, event.id);
      this.previousURL = this.currentURL;
      this.currentURL = event.url
      if (event.url.includes('/app/home')) {
        this.currentTabUri = 'app/home';
      } else if (event.url.includes('/app/tournaments')) {
        this.currentTabUri = 'app/tournaments';
      } else if (event.url.includes('/app/find')) {
        this.currentTabUri = 'app/find';
      } else if (event.url.includes('/app/main')) {
        this.currentTabUri = 'app/main';
      }
    });

    this.platform.ready().then(() => {
      this.storage.get('apiDomain').then(result => {
        if (result && result != '') {
          console.log("Storage Api Domain === ", result);
          this.apiDomain = result;
        } else {
          this.apiDomain = 'https://www.pitchaware.com';
          this.storage.set('apiDomain', this.apiDomain);
        }
        this.globalService.emit(GlobalEvents.domainLoaded, this.apiDomain);
      })

      this.globalService.listen(GlobalEvents.userProfiledLoaded).subscribe(() => {
        this.profileUpgraded()
      })
    })
  }

  public profileUpgraded() {
    this.storage.get('profile').then(profileData => {
      const profile = JSON.parse(profileData);
    })
  }

  public setApiDomain(domain): void {
    if (domain !== this.apiDomain) {
      this.apiDomain = domain
      this.storage.set('apiDomain', domain)
      this.globalService.emit(GlobalEvents.domainLoaded, domain)
    }
  }

  public savePrimeWelcomeMessageShown(): void {
    this.storage.set('prime', true)
  }

  public getPrimeWelcomeMessageValue(): Promise<any> {
    return this.storage.get('prime')
  }

  public getApiDomain(): Promise<any> {
    return this.storage.get('apiDomain')
  }

  public CREATE_CACHED_TIME(time: number): number {
    const milliseconds = time * 1000;
    return Date.now() + milliseconds;
  }

  public Headers(authenticated = true, cache = false, cacheDuration = 3600): HttpHeaders {
    let header = new HttpHeaders();

    if (authenticated) {
      header = header.set('Authorization', `Bearer ${window.localStorage.getItem('access_token')}`);
    }

    // if (cache) {
    //   header = header.set(LOCAL_CACHING_HEADER_NAME, this.CREATE_CACHED_TIME(cacheDuration).toString());
    // }

    return header;
  }

  public handleError(errorResponse): Promise<ErrorFormat>{
    const errorContent = {} as ErrorFormat;
    errorContent.status = errorResponse.status;
    /**
     * Maybe will be contained more code if api is completed with standard format of errors
     */

    return Promise.reject<ErrorFormat>(errorContent);
  }

  public getMonth(timestamp): string {
    const Months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    const date = new Date(timestamp);
    return Months[date.getMonth()];
  }

  public getYear(timestamp): string {
    const date = new Date(timestamp);
    return date.getFullYear().toString();
  }

  public getTime(timestamp): string {
    const date = new Date(timestamp);
    let hours = date.getHours();
    const ampm = hours > 12 ? 'pm' : 'am';
    hours = hours > 12 ? hours - 12 : hours;
    return hours + ampm;
  }

  public getDate(timestamp): string {
    const date = new Date(timestamp);
    return date.getDate().toString();
  }

  public showAlertMessage(title, message, callback?) {
    if (this.alertShowen == true) {
      return;
    }
    this.alertShowen = true;
    if (callback) {
      this.alertCtrl.create({
        header: title,
        message: message,
        buttons: [
          {
            text: 'OK',
            handler: () => {
              this.alertShowen = false;
            }
          },
          {
            text: 'End Other Stream',
            handler: () => {
              this.alertShowen = false;
              callback();
            }
          }
      ],
        backdropDismiss: false
      }).then(ctrl => {
          ctrl.present()
      })
    } else {
      this.alertCtrl.create({
        header: title,
        message: message,
        buttons: [{
          text: 'OK',
          handler: () => {
            this.alertShowen = false
          }
        }],
        backdropDismiss: false
      }).then(ctrl => {
          ctrl.present()
      })
    }
  }

  public showDetailAlertMessage(title, subTitle, message) {
    if (this.alertShowen == true) {
      return;
    }
    this.alertShowen = true;
    this.alertCtrl.create({
      header: title,
      subHeader: subTitle,
      message: message,
      buttons: [{
        text: 'OK',
        handler: () => {
          this.alertShowen = false
        }
      }],
      backdropDismiss: false
    }).then(ctrl => {
        ctrl.present()
    })
  }

  public showLoading(message) {
    this.loading = true
    return this.loadingCtrl.create({
      spinner: 'circular',
      message: message,
    }).then((a) => {
      a.present().then(() => {
        console.log("Loading Present ===== ")
        if (!this.loading) {
          a.dismiss().then(() => {
            console.log("Loading Dismiss ===== 22")
          })
        }
      })
      a.onDidDismiss();
    });
  }

  public async showLoadingWithTimer(message) {
    const modal = await this.loadingCtrl.create({
      spinner: 'circular',
      message: message,
    })
    await modal.present();
    setTimeout(() => {
      this.loadingCtrl.dismiss();
    }, 2000);
  }

  public showAppTypeSelection () {
    const inputArray: any[] = this.appTypes.map(type => {
      const checked = type.area == this.currentAppType.area && type.value == this.currentAppType.value ? true : false;
      const inputItem = {
        type: 'radio',
        value: type,
        checked: checked,
        label: type.name,
        disabled: type.disabled
      };
      return inputItem;
    })

    this.alertCtrl.create({
      header: 'Select Sport/Venue',
      inputs: inputArray,
      buttons: [
        {
          text: 'Cancel',
          handler: (data: any) => {
            console.log('Canceled', data);
          }
        },
        {
          text: 'OK',
          handler: (data: AppType) => {
            console.log('Selected appType', data);
            this.currentAppType = data;
            if (data.area == 'venue_id') {
              if (this.currentURL === '/app/find')
                this.venueAppTypeState.next(true);
              this.router.navigateByUrl('app/find');
            }
            if (data.area == 'sport_type') {
              if (this.currentURL === '/app/main' || this.currentURL === '/app')
                this.sportTypeState.next(true);
              this.router.navigateByUrl('app/main');
            }
          }
        }
      ]
    }).then(modal => {
      modal.present();
    })
  }

  public dismissAlert() {
    this.alertShowen = false;
    return this.alertCtrl.dismiss();
  }

  public dismissLoading() {
    this.loading = false;
    return this.loadingCtrl.dismiss().then(() => {
      console.log("Loading Dismiss ===== 11 ")
    }).catch(error => {
      console.log('Dismiss Error === ', error);
    });
  }

  public async showUpgradeAccountModal(data?: NavigationExtras, nextPage?: string, callback?) {
    const modal = await this.modalCtrl.create({
      component: UpgradeAccountComponent,
      cssClass: 'add-modal',
      swipeToClose: true,
      componentProps: {
        navigationData: data,
        page: nextPage
      }
    });
    modal.onDidDismiss().then(deatil => {
      if (callback && deatil.data.purchased) {
        callback();
      }
    })
    modal.present();
  }

  public async showOneTimePurchaseModal(json?: string, data?: NavigationExtras, nextPage?: string, callback?, keys?: Array<string>) {
    const modal = await this.modalCtrl.create({
      component: OneTimePurchasePage,
      cssClass: 'add-modal',
      componentProps: {
        navigationData: data,
        page: nextPage,
        json: json,
        keys: keys
      }
    });
    modal.onDidDismiss().then(detail => {
      if (callback && detail.data.purchased) {
        callback();
      }
    });
    modal.present();
    
  }

  public alertWithUpgrade(message) {
    this.alertCtrl.create({
      header: 'Not Accessible.',
      message: message,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: (blah) => {
          },
        },
        {
          text: 'OK',
          handler: () => {
            console.log('Confirm Okay');

            // this.router.navigateByUrl('purchase');
            this.showUpgradeAccountModal()
          },
        },
      ],
    }).then(ctrl => {
      ctrl.present();
    })
  }

  public checkAndGoVideoPlayer(navigationExtras) {
    const profile = JSON.parse(window.localStorage.getItem('profile'));
    if (!profile.access || !profile.access.level || profile.access.level < 5) {
      // this.baseService.showAlertMessage('Info', 'You need to upgrade to prime.')
      this.alertCtrl.create({
        header: 'Not Accessible.',
        message: 'You need to upgrade to prime.',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: (blah) => {
              console.log('Confirm Cancel: blah');
            },
          },
          {
            text: 'OK',
            handler: () => {
              console.log('Confirm Okay');

              // this.router.navigateByUrl('purchase');
              this.showUpgradeAccountModal()
            },
          },
        ],
      }).then(ctrl => {
        ctrl.present();
      })
      return;
    } 
    if (profile.access && profile.access.level && profile.access.level === 5) {
      this.router.navigateByUrl(this.currentTabUri + '/' + 'video-player', navigationExtras);
    }
  }

  public downloadOrOpenCharting() {
    let url = '';
    if (this.platform.is('ios')) {
      url = 'itms-apps://itunes.apple.com/app/1532160910'
    }
    if (this.platform.is('android')) {
      url = 'market://details?id=com.pitchaware.charting'
    }

    this.iab.create(url, "_system");
  }

  public navigateTo(url: string, navigationExtras?: any) {
    console.log('Current tab === ', this.currentTabUri);
    switch (url) {
      case 'video-player':
        // this.checkAndGoVideoPlayer(navigationExtras)
        // break;
      case 'player':
      case 'team':
      case 'game':
      case 'game-cast':
      case 'team-admin':
      case 'teams-admin':
      case 'team-admin-new':
      case 'game-center':
      case 'team-data':
      case 'team-roster':
      case 'team-schedule':
      case 'player-game':
      case 'player-profile-game':
      case 'livestream-player':
      case 'tournament-schedule':
      case 'notifications':
      case 'notification-setting':
      case 'game-center':
      case 'purchase':
      case 'map-event':
      case 'video-history':
        this.router.navigateByUrl(this.currentTabUri + '/' + url + '?time=' + Date.now(), navigationExtras);
        break;
      default:
        this.router.navigateByUrl(url, navigationExtras)
        break;
    }
  }

  
}

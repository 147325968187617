import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AddTeamComponent } from './add-team.component';

@NgModule({
  imports: [ CommonModule, FormsModule, IonicModule],
  declarations: [AddTeamComponent],
  exports: [AddTeamComponent]
})
export class AddTeamComponentModule {}

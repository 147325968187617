import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { BaseService } from 'src/app/services/base.service';
import { PlayerService } from 'src/app/services/player.service';
import { TeamService } from 'src/app/services/team.service';

@Component({
  selector: 'app-add-player',
  templateUrl: './add-player.component.html',
  styleUrls: ['./add-player.component.scss'],
})
export class AddPlayerComponent implements OnInit {

  public teamId: string = ''
  public firstName: string = ''
  public lastName: string = ''
  public hits: string = ''
  public throws: string = ''
  public day: number;
  public month: number;
  public year: number;
  public birth: any = null;
  public jerseyNumber: number = null;
  public position: string = ''
  public position1: string = '';
  public editing: boolean = false;
  public playerPK: number = null;

  constructor(
    private modalCtrl: ModalController,
    private playerService: PlayerService,
    private teamService: TeamService,
    private navParams: NavParams,
    private baseService: BaseService
  ) { }

  ngOnInit() {
    console.log('Team ID Param === ', this.navParams);
    this.teamId = this.navParams.data.teamId
    
    this.editing = this.navParams.data.playerPK ? true : false;
    this.playerPK = this.navParams.data.playerPK ? this.navParams.data.playerPK : null;
    this.firstName = this.navParams.data.first_name ? this.navParams.data.first_name : '';
    this.lastName = this.navParams.data.last_name ? this.navParams.data.last_name : '';
    this.day = this.navParams.data.day ? this.navParams.data.day : null;
    this.month = this.navParams.data.month ? this.navParams.data.month : null;
    this.year = this.navParams.data.year ? this.navParams.data.year : null;
    this.jerseyNumber = this.navParams.data.jersey_number ? this.navParams.data.jersey_number : null;
    this.position = this.navParams.data.position ? this.navParams.data.position : null;
    this.hits = this.navParams.data.hits != undefined ? this.navParams.data.hits + '' : '';
    this.throws = this.navParams.data.throws != undefined ? this.navParams.data.throws + '' : '';
    if (this.editing == true) {
      this.birth = new Date(this.year + '-' + this.month + '-' + this.day).toDateString()
    }

  }

  dismiss(event) {
    event.stopPropagation();
    this.modalCtrl.dismiss();
  }

  preventDismiss(event) {
    event.stopPropagation();
  }

  changeBirth = () => {
    console.log('Date Value === ', this.birth)
    
    this.day = new Date(this.birth).getDate();
    this.month= new Date(this.birth).getMonth() + 1;
    this.year = new Date(this.birth).getFullYear();
  }

  addPlayer = () => {
    if (this.firstName == '') {
      this.baseService.showAlertMessage('Error!', 'Please input the first name');
      return;
    }
    if (this.lastName == '') {
      this.baseService.showAlertMessage('Error!', 'Please input the last name');
      return;
    }
    if (this.hits == '') {
      this.baseService.showAlertMessage('Error!', 'Please select the hits');
      return;
    }
    if (this.throws == '') {
      this.baseService.showAlertMessage('Error!', 'Please select the throws');
      return;
    }
    // if (this.birth == null) {
    //   this.baseService.showAlertMessage('Error!', 'Please select the birthday');
    //   return;
    // }
    if (this.jerseyNumber == null || isNaN(this.jerseyNumber)) {
      this.baseService.showAlertMessage('Error!', 'Please input corrent Jersey Number');
      return;
    }
    if (this.jerseyNumber < 0 || this.jerseyNumber > 99) {
      this.baseService.showAlertMessage('Error!', 'Please input corrent Jersey Number');
      return;
    }
    let data = {}
    if (this.position) {
      if (this.position1 !== '') {
        data = {
          first_name: this.firstName,
          last_name: this.lastName,
          hits: this.hits,
          throws: this.throws,
          jersey_number: this.jerseyNumber,
          position: this.position + '/' + this.position1
        }
      } else {
        data = {
          first_name: this.firstName,
          last_name: this.lastName,
          hits: this.hits,
          throws: this.throws,
          jersey_number: this.jerseyNumber,
          position: this.position
        }
      }
      
    } else {
      data = {
        first_name: this.firstName,
        last_name: this.lastName,
        hits: this.hits,
        throws: this.throws,
        jersey_number: this.jerseyNumber,
      }
    }

    if (this.birth !== null && this.day && this.month && this.year && !isNaN(this.day) && !isNaN(this.month) && !isNaN(this.year)) {
      data = {
        ...data,
        day: this.day,
        month: this.month,
        year: this.year,
      }
    }
    this.baseService.showLoading('');
    this.teamService.addPlayerToTeam(this.teamId, data).then(() => {
      
    }).finally(() => {
      this.baseService.dismissLoading();
      this.modalCtrl.dismiss();
    });
  }

  editPlayer = () => {
    if (this.playerPK == null) {
      this.baseService.showAlertMessage('Error!', 'Player data is not correct');
      return;
    }
    if (this.firstName == '') {
      this.baseService.showAlertMessage('Error!', 'Please input the first name');
      return;
    }
    if (this.lastName == '') {
      this.baseService.showAlertMessage('Error!', 'Please input the last name');
      return;
    }
    if (this.hits == '') {
      this.baseService.showAlertMessage('Error!', 'Please select the hits');
      return;
    }
    if (this.throws == '') {
      this.baseService.showAlertMessage('Error!', 'Please select the throws');
      return;
    }
    // if (this.birth == null) {
    //   this.baseService.showAlertMessage('Error!', 'Please select the birthday');
    //   return;
    // }
    if (this.jerseyNumber == null || isNaN(this.jerseyNumber)) {
      this.baseService.showAlertMessage('Error!', 'Please input corrent Jersey Number');
      return;
    }
    if (this.jerseyNumber < 0 || this.jerseyNumber > 99) {
      this.baseService.showAlertMessage('Error!', 'Please input corrent Jersey Number');
      return;
    }
    let data = {}
    if (this.position) {
      data = {
        first_name: this.firstName,
        last_name: this.lastName,
        hits: this.hits,
        throws: this.throws,
        jersey_number: this.jerseyNumber,
        position: this.position
      }
    } else {
      data = {
        first_name: this.firstName,
        last_name: this.lastName,
        hits: this.hits,
        throws: this.throws,
        jersey_number: this.jerseyNumber,
      }
    }
    if (this.birth !== null && this.day && this.month && this.year && !isNaN(this.day) && !isNaN(this.month) && !isNaN(this.year)) {
      data = {
        ...data,
        day: this.day,
        month: this.month,
        year: this.year,
      }
    }
    this.baseService.showLoading('');
    this.teamService.editPlayerToTeam(this.teamId, data, this.playerPK).then(() => {
      
    }).finally(() => {
      this.baseService.dismissLoading();
      this.modalCtrl.dismiss();
    });
  }

}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { IonicStorageModule } from '@ionic/storage';
import { HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { CachingInterceptorService } from './services/interceptors/caching-interceptor.service';
import { RefreshTokenService } from './services/interceptors/refresh-token.service';
import { SideMenuModule } from './components/side-menu/side-menu.module';
import { FabComponentComponentModule } from './components/fab-component/fab-component.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VideoUpload } from 'ionic-plugin-video-upload/video-upload/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { ActionSheet } from '@ionic-native/action-sheet/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { InAppPurchase2 } from '@ionic-native/in-app-purchase-2/ngx';
import { InAppPurchase } from '@ionic-native/in-app-purchase/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { FormsModule } from '@angular/forms';
import { Push, PushObject, PushOptions } from '@ionic-native/push/ngx';
import { BranchIo } from '@ionic-native/branch-io/ngx';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { SMS } from '@ionic-native/sms/ngx';
import { ManageHomePageModule } from './pages/manage/manage-home/manage-home.module';
import { CreateTeamRosterPageModule } from './pages/manage/create-team-roster/create-team-roster.module';
import { CreateTeamSchedulePageModule } from './pages/manage/create-team-schedule/create-team-schedule.module';
import { Ionic4DatepickerModule } from
    '@logisticinfotech/ionic4-datepicker';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      backButtonText: ''
    }),
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    SideMenuModule,
    FabComponentComponentModule,
    BrowserAnimationsModule,
    FormsModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    SideMenuModule,
    FabComponentComponentModule,
    VideoUpload,
    EmailComposer,
    Camera,
    FileTransfer,
    ActionSheet,
    InAppPurchase2,
    InAppPurchase,
    InAppBrowser,
    SocialSharing,
    Push,
    BranchIo,
    GoogleAnalytics,
    FirebaseAnalytics,
    Geolocation,
    NativeStorage,
    FingerprintAIO,
    SMS,
    ManageHomePageModule,
    CreateTeamRosterPageModule,
    CreateTeamSchedulePageModule,
    Ionic4DatepickerModule,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenService, multi: true},
    HttpClientModule
  ],
  bootstrap: [AppComponent],
  exports: [HttpClientModule]
})
export class AppModule {}

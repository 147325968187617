import { Component, OnInit } from '@angular/core';
import { IonNav, ModalController, NavParams, Platform } from '@ionic/angular';
import { TeamCreationResult } from '../type';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';

@Component({
  selector: 'app-create-team-download',
  templateUrl: './create-team-download.page.html',
  styleUrls: ['./create-team-download.page.scss'],
})
export class CreateTeamDownloadPage implements OnInit {

  public teamData: TeamCreationResult;

  constructor(
    private nav: IonNav,
    private navParams: NavParams,
    private modalCtrl: ModalController,
    private iab: InAppBrowser,
    private platform: Platform
  ) { 
    this.teamData = this.navParams.get('prevData')
  }

  ngOnInit() {
  }

  finish = () => {
    this.modalCtrl.dismiss();
  }

  downloadCharting = () => {
    let url = '';
    if (this.platform.is('ios')) {
      url = 'itms-apps://itunes.apple.com/app/1532160910'
    }
    if (this.platform.is('android')) {
      url = 'market://details?id=com.pitchaware.charting'
    }

    this.iab.create(url, "_system");
  }

}

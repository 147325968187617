import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { BaseService } from 'src/app/services/base.service';
import { TeamService } from 'src/app/services/team.service';
import _ from 'lodash';
import { Ionic4DatepickerModalComponent } from '../date-picker/ionic4-datepicker-modal/ionic4-datepicker-modal.component';

const monthArray = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

interface PostData {
  date: string;
  location_name: string;
  playing_self?: boolean;
  innings_playing: string;
  team?: string;
  new_team?: string;
  game_time?: string;
}

@Component({
  selector: 'app-add-schedule',
  templateUrl: './add-schedule.component.html',
  styleUrls: ['./add-schedule.component.scss'],
})
export class AddScheduleComponent implements OnInit {

  public disabledDates: Date[] = [
    new Date(1545911005644),     
    new Date(),     
    new Date(2018, 12, 12), // Months are 0-based, this is August, 10th.     
    new Date('Wednesday, December 26, 2018'), // Works with any valid Date formats like long format     
    new Date('12-14-2018'), // Short format
  ];
  public datePickerObj: any = {
    inputDate: new Date(), // default new Date()
    // fromDate: null, // default null
    // toDate: new Date('2018-12-28'), // default null
    showTodayButton: false, // default true
    // closeOnSelect: true, // default false
    // disableWeekDays: [4], // default []
    // mondayFirst: true, // default false
    // setLabel: 'S',  // default 'Set'
    // todayLabel: 'T', // default 'Today'
    // closeLabel: 'C', // default 'Close'
    // disabledDates: disabledDates, // default []
    // titleLabel: 'Select a Date', // default null
    // monthsList: ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"],
    // weeksList: ["S", "M", "T", "W", "T", "F", "S"],
    dateFormat: 'MM/DD/YY', // default DD MMM YYYY
    clearButton : false , // default true
    // momentLocale: 'pt-BR', // Default 'en-US'
    // yearInAscending: true, // Default false
    // btnCloseSetInReverse: true, // Default false
    // btnProperties: {
    //   expand: 'block', // Default 'block'
    //   fill: '', // Default 'solid'
    //   size: '', // Default 'default'
    //   disabled: '', // Default false
    //   strong: '', // Default false
    //   color: '' // Default ''
    // },
    // arrowNextPrev: {
    //   nextArrowSrc: 'assets/images/arrow_right.svg',
    //   prevArrowSrc: 'assets/images/arrow_left.svg'
    // }, // This object supports only SVG files.
    // highlightedDates: [
    // { date: new Date('2019-09-10'), color: '#ee88bf', fontColor: '#fff' },
    // { date: new Date('2019-09-12'), color: '#50f2b1', fontColor: '#fff' }
    // ], // Default [],
    // isSundayHighlighted : {
    //   fontColor: '#ee88bf' // Default null
    // } // Default {}
  };

  public teamId: string = '';
  public selectTeamOptions: Array<any> = [];
  public date: string = '';
  public displayDate: string = ''
  public selfPlay: boolean = false;
  public team: string = '';
  public newTeam: string = '';
  public inningPlaying: string = '7';
  public location: string = '';
  public time: string = '';
  public timeZone: string = '';
  public newTeamNeed: boolean = false;
  public callbackNewTeam: any;
  

  constructor(
    private modalCtrl: ModalController,
    private teamService: TeamService,
    private navParams: NavParams,
    private baseService: BaseService
  ) { 

  }

  ngOnInit() {
    this.teamId = this.navParams.data.teamId
    this.timeZone = this.navParams.data.timeZone;
    // this.callbackNewTeam = this.navParams.data.callBack;
    // this.newTeamNeed = this.navParams.data.enableEdit;
  }

  dismiss(event) {
    event.stopPropagation();
    this.modalCtrl.dismiss();
  }

  preventDismiss(event) {
    event.stopPropagation();
  }

  ionViewWillEnter () {
    this.baseService.showLoading('Loading...');
    this.teamService.getScheduleFormData(this.teamId).then(formResult => {
      const selectTempOptions = _.filter(formResult, item => item.key == 'team');
      this.selectTeamOptions = selectTempOptions[0].templateOptions.options;
      const dateOptions = _.find(formResult, item => item.key == 'date');
      const dateOptionLength = dateOptions?.templateOptions?.options?.length || 0
      if (dateOptionLength > 0) {
        this.datePickerObj = {
          ...this.datePickerObj,
          fromDate: new Date(dateOptions.templateOptions.options[0].value),
          toDate: new Date(dateOptions.templateOptions.options[dateOptionLength - 1].value)
        }
      }
    }).finally(() => {
      this.baseService.dismissLoading();
    })
  }

  formatDate(date) {
    let d = new Date(date),
        month = d.getMonth(),
        day = d.getDate(),
        year = d.getFullYear();
    let dayStr = '' + day;
    if (day < 10) 
      dayStr = '0' + day;

    return monthArray[month] + ' ' + dayStr + ', ' + year;
  }

  convertDate(date) {
    let d = new Date(date),
        month = d.getMonth() + 1,
        day = d.getDate(),
        year = d.getFullYear();
    let dayStr = '' + day;
    let monthStr = '' + month;
    if (day < 10) 
      dayStr = '0' + day;
    if (month < 10)
      monthStr = '0' + month;
    return year + '-' + monthStr + '-' + dayStr;
  }

  formatTime(date) {
    const d = new Date(date);
    const h = d.getHours();
    const s = d.getMinutes();

    return [h, s].join(':');
  }

  addSchedule = () => {
    console.log("Add Game === ", this.selfPlay, this.team, this.newTeam)

    if (this.date == '') {
      this.baseService.showAlertMessage('Error!', 'Please select the game date');
      return;
    }
    if (this.location == '') {
      this.baseService.showAlertMessage('Error!', 'Please input the game location');
      return;
    }
    if (this.team == '') {
      this.baseService.showAlertMessage('Error!', 'Please select one option of Intrasquad, Select Team or Add New Team.');
      return;
    }
    if (this.team == 'newTeam' && this.newTeam == '') {
      this.baseService.showAlertMessage('Error!', 'You can select the opponent team or add a new opponent team.');
      return;
    }
    if (this.inningPlaying == '' || isNaN(parseInt(this.inningPlaying))) {
      this.baseService.showAlertMessage('Error!', 'Please input the correct Innings Play');
      return;
    }

    if (this.selfPlay && this.team != 'intrasquad' && this.team != 'newTeam') {
      this.baseService.showAlertMessage('Error!', 'You can only set one option of Intrasquad, Select Team or Add New Team.');
      return;
    }

    let postData: PostData = {
      date: this.convertDate(this.date),
      location_name: this.location,
      playing_self: this.selfPlay,
      innings_playing: this.inningPlaying
    }
    
    if (this.selfPlay == false) {
      if (this.team != '' && this.newTeam == '') {
        postData = {
          ...postData,
          team: this.team,
          new_team: ''
        }
      }
  
      if (this.team == 'newTeam' && this.newTeam != '') {
        postData = {
          ...postData,
          team: '',
          new_team: this.newTeam
        }
      }
    }
    if (this.time != '') {
      postData = {
        ...postData,
        game_time: this.formatTime(this.time)
      }
    }
    this.baseService.showLoading('');
    this.teamService.addTeamSchedule(this.teamId, postData).then((result) => {
      console.log("Team Add schedule result === ", result);
    }).catch(error => {
      this.baseService.showAlertMessage('Error!', 'Something went wrong!');
    }).finally(() => {
      this.baseService.dismissLoading();
      this.modalCtrl.dismiss();
    })
  }

  changeOpponent = () => {
    console.log("Change Opponent === ", this.team, this.date)
    if (this.team === 'newTeam') {
      this.callbackNewTeam && this.callbackNewTeam(true);
      this.newTeamNeed = true;
    } else{
      this.callbackNewTeam && this.callbackNewTeam(false);
      this.newTeamNeed = false;
      this.newTeam = '';
      if (this.team === 'intrasquad') {
        this.selfPlay = true;
      } else {
        this.selfPlay = false;
      }
    } 
  }

  changeDate = (event) => {
    console.log("DAte === ")
  }

  openDatePicker = async () => {
    const datePickerModal = await this.modalCtrl.create({
      component: Ionic4DatepickerModalComponent,
      cssClass: 'li-ionic4-datePicker',
      componentProps: { 'objConfig': this.datePickerObj }
    });
    await datePickerModal.present();

    datePickerModal.onDidDismiss().then((data) => {
      if (data.data.date != 'Invalid date') {
        this.date = data.data.date;
        this.displayDate = this.formatDate(this.date)
      }
    });
  }

}

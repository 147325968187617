import { Component, OnInit } from '@angular/core';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { IonNav, ModalController, NavParams } from '@ionic/angular';
import { SMS } from '@ionic-native/sms/ngx';
import { BaseService } from 'src/app/services/base.service';
import { TeamCreationResult } from '../type';
import { CreateTeamDownloadPage } from '../create-team-download/create-team-download.page';

@Component({
  selector: 'app-create-team-invite',
  templateUrl: './create-team-invite.page.html',
  styleUrls: ['./create-team-invite.page.scss'],
})
export class CreateTeamInvitePage implements OnInit {

  public teamData: TeamCreationResult
  
  constructor(
    private nav: IonNav,
    private navParams: NavParams,
    private modalCtrl: ModalController,
    private emailComposer: EmailComposer,
    private sms: SMS,
    private baseService: BaseService
  ) {
    this.teamData = this.navParams.get('prevData')
   }

  ngOnInit() {

  }

  finish = () => {
    this.nav.push(CreateTeamDownloadPage, {prevData: this.teamData})
  }

  clickSkip = () => {
    this.modalCtrl.dismiss();
  }

  openMessage = (type) => {
    let inviteURL = '';
    let message = '';
    if (type === 'coach') {
      inviteURL = this.teamData.admin.staff_invite_url;
      message = 'You have been invited to PitchAware as a coach of ' + this.teamData.name + '  ' + inviteURL;
    } else if (type === 'manager') {
      inviteURL = this.teamData.admin.scorekeeper_invite_url
      message = 'You have been invited to PitchAware as a team manager for ' + this.teamData.name + '  ' + inviteURL;
    } else if (type === 'player') {
      inviteURL = this.teamData.fan_invite_url;
      message = 'You have been invited to PitchAware to follow ' + this.teamData.name + '  ' + inviteURL;
    }
    //CONFIGURATION 
    var options = {
      replaceLineBreaks: false, // true to replace \n by a new line, false by default
      android: {
          intent: 'INTENT'  // send SMS with the native android SMS messaging
          //intent: '' // send SMS without opening any other app, require : android.permission.SEND_SMS and android.permission.READ_PHONE_STATE
      }
    };
    this.sms.send('', message, options).then(() => {
      this.baseService.showAlertMessage('Success', 'SEMS Send');
    }).catch(error => {
      // this.baseService.showAlertMessage('Fail', 'SEMS Send Error');
    })
  }

  openMail = (type) => {
    let inviteURL = '';
    let message = '';
    if (type === 'coach') {
      inviteURL = this.teamData.admin.staff_invite_url;
      message = 'You have been invited to PitchAware as a coach of ' + this.teamData.name + '  ' + inviteURL;
    } else if (type === 'manager') {
      inviteURL = this.teamData.admin.scorekeeper_invite_url
      message = 'You have been invited to PitchAware as a team manager for ' + this.teamData.name + '  ' + inviteURL;
    } else if (type === 'player') {
      inviteURL = this.teamData.fan_invite_url;
      message = 'You have been invited to PitchAware to follow ' + this.teamData.name + '  ' + inviteURL;
    }
    let email = {
      to: '',
      cc: '',
      bcc: [],
      subject: 'Please get this invitation!',
      body: message,
      isHtml: true
    }
    
    // Send a text message using default options
    this.emailComposer.open(email).catch(error => {
      console.log('Email Open Error === ', JSON.stringify(error));
    });
  }

}

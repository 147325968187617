import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BaseService } from '../../services/base.service';
import { TeamService } from '../../services/team.service';
import _ from 'lodash';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-add-team',
  templateUrl: './add-team.component.html',
  styleUrls: ['./add-team.component.scss'],
})
export class AddTeamComponent implements OnInit {

  public name: string = '';
  public scoreboard_name: string = '';
  public city: string = '';
  public us_state: string = '';
  public ca_province: string = '';
  public base_distance: string = '90';
  public mound_distance: string = '60';
  public country: string = 'US';
  public competition_level: string  = '';
  public usStateList: Array<any> = [
    {
      value: "AK",
      name: "Alaska",
    },
    {
      value: "AL",
      name: "Alabama"
    },
    {
      value:"AR",
      name: "Arkansas",
    },
    {
      value: "AZ",
      name: "Arizona",
    },
    {
      value: "CA",
      name: "California"
    },
    {
      value: "CO",
      name: "Colorado"
    },
    {
      value: "CT",
      name: "Connecticut"
    },
    {
      value: "DC",
      name: "District of Columbia"
    },
    {
      value: "DE",
      name: "Delaware"
    },
    {
      value: "FL",
      name: "Florida"
    },
    {
      value: "GA",
      name: "Georgia"
    },
    {
      value: "HI",
      name: "Hawaii"
    },
    {
      value: "IA",
      name: "Iowa"
    },
    {
      value: "ID",
      name: "Idaho"
    },
    {
      value: "IL",
      name: "Illinois"
    },
    {
      value: "IN",
      name: "Indiana"
    },
    {
      value: "KS",
      name: "Kansas"
    },
    {
      value: "KY",
      name: "Kentucky"
    },
    {
      value: "LA",
      name: "Louisiana"
    },
    {
      value: "MA",
      name: "Massachusetts"
    },
    {
      value: "MD",
      name: "Maryland"
    },
    {
      value: "ME",
      name: "Maine"
    },
    {
      value: "MI",
      name: "Michigan"
    },
    {
      value: "MN",
      name: "Minnesota"
    },
    {
      value: "MO",
      name: "Missouri"
    },
    {
      value: "MS",
      name: "Mississippi"
    },
    {
      value: "MT",
      name: "Montana"
    },
    {
      value: "NC",
      name: "North Carolina"
    },
    {
      value: "ND",
      name: "North Dakota"
    },
    {
      value: "NE",
      name: "Nebraska"
    },
    {
      value: "NH",
      name: "New Hampshire"
    },
    {
      value: "NJ",
      name: "New Jersey"
    },
    {
      value: "NM",
      name: "New Mexico"
    },
    {
      value: "NV",
      name: "Nevada"
    },
    {
      value: "NY",
      name: "New York"
    },
    {
      value: "OH",
      name: "Ohio"
    },
    {
      value: "OK",
      name: "Oklahoma"
    },
    {
      value: "OR",
      name: "Oregon"
    },
    {
      value: "PA",
      name: "Pennsylvania"
    },
    {
      value: "RI",
      name: "Rhode Island"
    },
    {
      value: "SC",
      name: "South Carolina"
    },
    {
      value: "SD",
      name: "South Dakota"
    },
    {
      value: "TN",
      name: "Tennessee"
    },
    {
      value: "TX",
      name: "Texas"
    },
    {
      value: "UT",
      name: "Utah"
    },
    {
      value: "VA",
      name: "Virginia"
    },
    {
      value: "VT",
      name: "Vermont"
    },
    {
      value: "WA",
      name: "Washington"
    },
    {
      value: "WI",
      name: "Wisconsin"
    },
    {
      value: "WV",
      name: "West Virginia"
    },
    {
      value: "WY",
      name: "Wyoming"
    }
  ]
  public canadaProvinceList: Array<any> = [];

  constructor(
    private modalCtrl: ModalController,
    private baseService: BaseService,
    private teamService: TeamService,
    private router: Router
  ) { }

  ngOnInit() {
    
  }

  ionBackdropTap() {
    console.log('Back drop Tapped!!!');
  }

  ionViewWillEnter() {
    this.baseService.showLoading('');
    this.teamService.getAddTeamForm().then(result => {
      this.canadaProvinceList = _.filter(result, item => item.key === 'ca_province')[0].templateOptions.options;
      this.usStateList = _.filter(result, item => item.key === 'us_state')[0].templateOptions.options;
      this.baseService.dismissLoading();
    }).finally(() => {
      this.baseService.dismissLoading();
    });
  }

  

  dismiss(event) {
    event.stopPropagation();
    this.modalCtrl.dismiss();
  }

  preventDismiss(event) {
    event.stopPropagation();
  }

  addTeam = () => {
    
    if (this.name == '') {
      this.baseService.showAlertMessage('Error!', 'Please input the team name.');
      return;
    }
    if (this.scoreboard_name == '') {
      this.baseService.showAlertMessage('Error!', 'Please input the scoreboard name.');
      return;
    }
    if (this.country == '') {
      this.baseService.showAlertMessage('Error!', 'Please select the country name.');
      return;
    }
    if (this.city == '') {
      this.baseService.showAlertMessage('Error!', 'Please input the city name.');
      return;
    }
    if (this.country == 'US' && this.us_state === '') {
      this.baseService.showAlertMessage('Error!', 'Please select the state name.');
      return;
    }
    if (this.country == 'CA' && this.ca_province === '') {
      this.baseService.showAlertMessage('Error!', 'Please select the province name.');
      return;
    }
    if (this.base_distance == '') {
      this.baseService.showAlertMessage('Error!', 'Please select the base distance.');
      return;
    }
    if (this.mound_distance == '') {
      this.baseService.showAlertMessage('Error!', 'Please select the mound distance.');
      return;
    }
    if (this.competition_level == '') {
      this.baseService.showAlertMessage('Error!', 'Please select the competition level.');
      return;
    }

    const data = {
      name: this.name,
      scoreboard_name: this.scoreboard_name,
      country: this.country,
      city: this.city,
      base_distance: this.base_distance,
      mound_distance: this.mound_distance,
      competition_level: this.competition_level
    }
    let postData = {}
    if (this.country === 'US') {
      postData = {
        ...data,
        us_state: this.us_state
      }
    }
    if (this.country === 'CA') {
      postData = {
        ...data,
        ca_province: this.ca_province
      }
    }
    const profile = JSON.parse(window.localStorage.getItem('profile'));
    // const facilityId = profile.facility.pk;
    
    this.baseService.showLoading('');
    this.teamService.addTeam(postData).then(result => {
      this.baseService.dismissLoading();
      this.modalCtrl.dismiss();
      console.log('Add Team Result  ==== ', result);
      const navigationExtras: NavigationExtras = {
        state: {
          teamId: result.detail.pk,
        },
      };
      // this.router.navigateByUrl('team', navigationExtras);
      this.baseService.navigateTo('team', navigationExtras);
    }).catch(error => {
      console.log('Team Add Error === ');
    }).finally(() => {
      this.baseService.dismissLoading();
    })
  }

}

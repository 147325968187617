import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayConvert'
})
export class ArrayConvertPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return Object.values(value); 
  }

}

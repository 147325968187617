import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { environment } from '../../environments/environment';
import { Storage } from '@ionic/storage';
import { BaseService } from './base.service';
import { RequestCachingService } from './interceptors/request-caching.service';
import { Observable, of, EMPTY, BehaviorSubject, from } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { GlobalEmitterService, GlobalEvents } from './global-emitter.service'
import { NativeStorage } from '@ionic-native/native-storage/ngx';
// import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { Router } from '@angular/router';
import { ProfileData, UserService } from './user.service';

export interface UserData {
  email: string;
  password: any;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authenticationState = new BehaviorSubject(false);

  public faceIdAvailable: boolean = false;
  public bioSkip: boolean = false;

  constructor(
    private http: HttpClient,
    private platform: Platform,
    private baseService: BaseService,
    private cacheService: RequestCachingService,
    private globalService: GlobalEmitterService,
    private storage: Storage,
    private nativeStorage: NativeStorage,
    // private fio: FingerprintAIO,
    private router: Router,
    private userService: UserService
  ) { 
    this.platform.ready().then(() => {
      this.checkToken();

      // this.fio.isAvailable().then(value => {
      //   if (value) this.faceIdAvailable = true;
      //   else this.faceIdAvailable = false;
      // }).catch(error => { this.faceIdAvailable = false; })
    })
  }

  // public checkBioAvailable(): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     this.fio.isAvailable().then(value => {
  //       console.log("Face ID available === ", value);
  //       if (value) this.faceIdAvailable = true;
  //       else this.faceIdAvailable = false;
  //       resolve(true);
  //     }).catch(error => { 
  //       this.faceIdAvailable = false; 
  //       resolve(false);
  //     })
  //   })
    
  // }

  public checkToken() {
    // let storageToken = window.localStorage.getItem('access_token');
    // if (storageToken) {
    //   this.authenticationState.next(true);
    // }
    this.storage.get('access_token').then(value => {
      if (value) {
        this.authenticationState.next(true);
      }
    })
  }

  public saveAuthData(authData) {
    this.storage.set('access_token', authData.access_token);
    this.storage.set('auth_data', JSON.stringify(authData));
    this.storage.set('refresh_token', authData.refresh_token);
    window.localStorage.setItem('access_token', authData.access_token);
    window.localStorage.setItem('refresh_token', authData.refresh_token);
    window.localStorage.setItem('expires_in', authData.expires_in);
    window.localStorage.setItem('token_type', authData.token_type);
    window.localStorage.setItem('scope', authData.scope);
    window.localStorage.setItem('auth_data', JSON.stringify(authData));
    // window.localStorage.setItem('foundations_access', authData.foundations_access);
    // window.localStorage.setItem('foundations_month', authData.foundations_month);
    console.log('Window.localStorage ======= ', window.localStorage);
    // this.globalService.emit(GlobalEvents.userProfiledLoaded);
  }

  public refreshToken(): Observable<any> {
    return from(this.storage.get('refresh_token'))
    .pipe(
      switchMap(refreshToken => {
        console.log('Refresh Token 111111');
        const url = this.baseService.apiDomain + environment.emailSignInPath;
        const body = encodeURIComponent('grant_type') + '=' + encodeURIComponent('refresh_token') + '&' + encodeURIComponent('refresh_token') + '=' + encodeURIComponent(refreshToken);
        // let headers = new HttpHeaders();
        let headers = this.baseService.Headers(false, false);
        const authCode = environment.clientId + ':' + environment.clientSecret;
        headers = headers.set('Content-type', 'application/x-www-form-urlencoded');
        headers = headers.set('Authorization', 'Basic' + btoa(authCode));
        console.log('Refresh Token 22222');
        return this.http.post<any>(url, body, {
          headers
        }).pipe(switchMap(response => {
          console.log('Refresh Token Response ===', response);
          if (response.status == 200) {
              this.saveAuthData(response.data);
          }
          return of(response);
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('Refresh Token An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(`Refresh Token Backend returned code ${error.status}, body was: ${error.error}`);
          }

          return EMPTY;
        })
        );
      })
    )
    // const refresh_token = window.localStorage.getItem('refresh_token');
    // if (!refresh_token) {
    //   return;
    // }
    
  }

  public signIn(userData: UserData): Promise<any> {
    const url = this.baseService.apiDomain + environment.emailSignInPath;
    const body = encodeURIComponent('username') + '=' + encodeURIComponent(userData.email) + '&' + encodeURIComponent('password') + '=' + encodeURIComponent(userData.password) + '&' + encodeURIComponent('grant_type') + '=' + encodeURIComponent('password') + '&' + encodeURIComponent('client_id') + '=' + encodeURIComponent(environment.clientId) + '&' + encodeURIComponent('client_secret') + '=' + encodeURIComponent(environment.clientSecret);

    // let headers = new HttpHeaders();
    let headers = this.baseService.Headers(false, false);
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');

    // return this.http.request()
    console.log("Signin URL ==== ", url);
    return this.http.post<any>(url, body.toString(), {
      headers
    }).toPromise()
    .then(result => {
      // Save storage api domain by default
      this.baseService.setApiDomain(this.baseService.apiDomain);
      console.log('SignIn Result === ', result);
      this.nativeStorage.setItem('user_credential', userData);
      this.saveAuthData(result);
      this.authenticationState.next(true);
      return result;
    });
  }

  public signOut(): Promise<any> {
    const access_token = window.localStorage.getItem('access_token');
    if (!access_token) {
      // Force Logout
      this.authenticationState.next(false);
      return Promise.resolve(true);
    }
    const url = this.baseService.apiDomain + environment.signOutUrl;

    return this.http.post<any>(url, {token: access_token}, {}).toPromise().then((result) => {
      window.localStorage.clear();
      // this.cacheService.clearAll();
      this.storage.get('user_credential').then(value => {
        this.storage.clear();
        this.storage.set('user_credential', value)
      }).catch(() => {
        this.storage.clear();
      })

      // this.nativeStorage.getItem('user_credential').then(value => {

      // })
      this.authenticationState.next(false);
      return result;
    });
  }

  public afterLoginWork() {
    this.storage.get('device_token').then(token => {
      if (token && token != '') {
        // this.userService.registerDevice(token);
      }
    })
    this.userService.getProfile().then((value: ProfileData) => {
      if (value.favorite_teams.length > 0) {
        this.router.navigateByUrl('app')
      } else {
        this.router.navigateByUrl('/app/find')
      }
    }).catch(error => {
      console.log('Get Profile Error === ', JSON.stringify(error));
    });
  }

  public bioSign() {
    // this.fio.show({
    //   cancelButtonTitle: 'Cancel',
    //   description: "Please Use Biometric Authentication",
    //   disableBackup: true,
    //   title: 'Scan Biometric Information',
    //   fallbackButtonTitle: 'FallBack',
    //   subtitle: ''
    // }).then((value) => {
    //   this.storage.get('access_token').then(value => {
    //     if (value) {
    //       this.afterLoginWork();
    //     }
    //   })
    // }).catch(error => {
    //   this.baseService.showAlertMessage('Error', 'Not able to verify biometric information');
    // })
  }

  public loginWithPreviousCredentials() {
    this.nativeStorage.getItem('user_credential').then(value => {
      console.log("Native Stroage Credentail Signin === ", JSON.stringify(value))
      this.signIn(value).then(result => {
        this.bioSign();
      }).catch(error => this.router.navigateByUrl('new-login'))
    }).catch(error => this.router.navigateByUrl('new-login'))
  }

  public checkAndGoSignin() {
    this.nativeStorage.getItem('user_credential').then(value => {
      if (value) {
        if (this.faceIdAvailable == true && this.bioSkip == false) this.router.navigateByUrl('bio-sign')
        else this.router.navigateByUrl('new-login')
      } else {
        this.router.navigateByUrl('new-login')
      }
    }).catch(error => {
      this.router.navigateByUrl('new-login')
    })
  }
}

import { Component, OnInit } from '@angular/core';
import { IonNav, NavParams, ActionSheetController } from '@ionic/angular';
import { BaseService } from 'src/app/services/base.service';
import { CreateTeamData, TeamCreationResult } from '../type';
import { CreateTeamPrivacyPage } from '../create-team-privacy/create-team-privacy.page';
import { TeamService } from 'src/app/services/team.service';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { FileTransfer, FileUploadOptions } from '@ionic-native/file-transfer/ngx'
import { Storage } from '@ionic/storage';
import { CreateTeamFinishPage } from '../create-team-finish/create-team-finish.page';

@Component({
  selector: 'app-create-team-detail',
  templateUrl: './create-team-detail.page.html',
  styleUrls: ['./create-team-detail.page.scss'],
})
export class CreateTeamDetailPage implements OnInit {
  
  public teamData: CreateTeamData = {};
  public teamName: string = '';
  public scoreboardName: string = '';
  public competitionLevel: number = null;
  public competitionLevels: Array<any> = [
    {name: "5U", value: 5},
    {name: "6U", value: 6},
    {name: "7U", value: 7},
    {name: "8U", value: 8},
    {name: "9U", value: 9},
    {name: "10U", value: 10},
    {name: "11U", value: 11},
    {name: "12U", value: 12},
    {name: "13U", value: 13},
    {name: "14U", value: 14},
    {name: "15U", value: 15},
    {name: "16U", value: 16},
    {name: "17U", value: 17},
    {name: "18U", value: 18},
    {name: "High School", value: 30},
    {name: "College", value: 50},
    {name: "Pro", value: 100},
    {name: "Adult", value: 150}
  ];
  public fileURL: string = '';
  public fileName: string = '';

  constructor(
    private nav: IonNav,
    private navParams: NavParams,
    private baseService: BaseService,
    private teamService: TeamService,
    private actionSheet: ActionSheetController,
    private camera: Camera,
    private fileTransfer: FileTransfer,
    private storage: Storage,
  ) { }

  ngOnInit() {
    console.log("Navigation Params === ", this.navParams.get('prevData'))
    this.teamData = this.navParams.get('prevData')
  }

  changeTeamName = (event) => {
    if (event.target.value.length > 150) {
      this.baseService.showAlertMessage('Error!', 'Invalid team name!');
      return;
    }
    this.teamName = event.target.value;
  }

  changeScoreboard = (event) => {
    if (event.target.value.length > 150) {
      this.baseService.showAlertMessage('Error!', 'Invalid scoreboard name!');
      return;
    }
    this.scoreboardName = event.target.value;
  }

  clickNext = () => {
    if (this.teamName === '') {
      this.baseService.showAlertMessage('Error!', 'Please input the team name!');
      return;
    }

    // if (this.scoreboardName === '') {
    //   this.baseService.showAlertMessage('Error!', 'Please input the scoreboard name!');
    //   return;
    // }

    if (this.competitionLevel === null) {
      this.baseService.showAlertMessage('Error!', 'Please choose the competition level!');
      return;
    }

    this.teamData.name = this.teamName;
    this.teamData.scoreboard_name = this.teamName;
    this.teamData.competition_level = this.competitionLevel;
    this.teamData.logo_file = this.fileURL;
    this.teamData.logo_file_name = this.fileName;
    this.teamData.is_private = 1;

    // this.nav.push(CreateTeamPrivacyPage, {prevData: this.teamData})

    if (this.teamData.logo_file && this.teamData.logo_file != '') {
      this.storage.get('access_token').then(token =>{
        const uploadOptions: FileUploadOptions = {
          fileKey: 'logo_file',
          fileName: this.teamData.logo_file_name,
          chunkedMode: false,
          params: this.teamData,
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        const uploadURL = this.baseService.apiDomain + '/api/team/create'
        const uploadObj = this.fileTransfer.create();
        this.baseService.showLoading('Uploading...');
        uploadObj.upload(this.teamData.logo_file, uploadURL, uploadOptions).then((result) => {
          this.baseService.dismissLoading();
          const response = JSON.parse(result.response);
          console.log('Upload Succss ==== ', JSON.stringify(response));
          if (response.status === 'ok') {
            this.teamService.teamAddState.next(true);
            const teamData: TeamCreationResult = response.detail.team;
            console.log("Team Creation Result ==== ", teamData);
            this.nav.push(CreateTeamFinishPage, {prevData: teamData})
          }
        }).catch(error => {
          this.baseService.dismissLoading();
          console.log('Upload Failed');
          if (error.http_status == 401) {
            this.baseService.showAlertMessage('Error', 'Authentication is invalid');
          } else {
            this.baseService.showAlertMessage('Error', 'Upload Failed!');
          }
        })
      }).catch(error => {
        this.baseService.showAlertMessage('Error!', 'Failed to get the access token!');
      })
    } else {
      this.teamService.postCreateTeam(this.teamData).then(result => {
        if (result.status === 'ok') {
          this.teamService.teamAddState.next(true);
          const teamData: TeamCreationResult = result.detail.team;
          console.log("Team Creation Result ==== ", teamData);
          this.nav.push(CreateTeamFinishPage, {prevData: teamData})
        } else {
          this.baseService.showAlertMessage('Error!', 'Your team creation was failed.');
        }
        
      }).catch(error => {
        this.baseService.showAlertMessage('Error!', 'Your team creation was failed.');
      })
      // this.nav.push(CreateTeamFinishPage, {prevData: 123})
    }
  }

  selectImage = (source: string, ) => {
    let options: CameraOptions = {
      destinationType: this.camera.DestinationType.FILE_URI,
      sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
      mediaType: this.camera.MediaType.PICTURE
    }
    if (source == 'camera') {
      options.sourceType = this.camera.PictureSourceType.CAMERA
    }
    this.camera.getPicture(options).then(result => {
      console.log('Camera Result === ', result);
      this.fileURL = result.indexOf('file://') == 0 ? result :' file://' + result;
      this.fileName = this.fileURL.substr(this.fileURL.lastIndexOf('/') + 1);
    })
  }

  showUploadOptions = () => {
    this.actionSheet.create({
      header: 'How do you get the image?',
      cssClass: 'my-action-sheet',
      buttons: [
        {
          text: 'Library',
          handler: () => {
            this.selectImage('library');
          }
        },
        {
          text: 'Camera',
          handler: () => {
            this.selectImage('camera');
          }
        }
      ]
    }).then((atModal) => {
      atModal.present()
    })
  }

  clearFile = () => {
    this.fileName = '';
    this.fileURL = ''
  }

}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://www.pitchaware.com', // 'http://localhost:8000',//
  openUrl: 'https://www.pitchaware.com', // 'http://localhost:8000',//
  // apiUrl: 'https://dev.birdiefire.com',
  // openUrl: 'https://dev.birdiefire.com',
  mediaUrl : 'https://pitchaware-web-prod.s3.amazonaws.com/media/', // 'http://localhost:8000/media/',//
  devMediaUrl: 'https://pitchaware-web-dev.s3.amazonaws.com/media/',
  deepUrl : 'https://q4yy2.app.link',
  test : false,
  devicecheck : true,
  pushidentifier: 'pitchaware',
  hideTutorial : true,
  support_email : 'support@pitchaware.com',
  deepChartingUrl : 'https://wh4au.app.link',
  emailSignInPath: '/o/token/',
  clientId: 'An931tYhtpvu6WRWVXhcxAKy8OfvLhP8jWnWRVOm',
  clientSecret: 'RD0mFUNOB8wrGMFSrUkE2CrpGgCkjcqmhMpfIGIvQgDNnxo7c1cQkYc4tEJZDfSEfmqcrMIyyukLX6nYk9teOSaZI388nuows2x7k3CMRnwKvJJ1DkUpDG85oAVhpFtb',
  signOutUrl: '/api/logout/',
  profileUrl: '/api/profile/',
  passwordResetPath: '/api/reset_password/',
  cacheEnable: true,
  cacheTime: 3600,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

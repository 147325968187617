import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { ProfileData } from './user.service';
import { Location } from '@angular/common';
import { LeagueService, TournamentsData } from './league.service';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  public resumeSubscriber: any = null;

  constructor(
    private router: Router,
    private location: Location,
    private leagueService: LeagueService,
    private alertCtrl: AlertController
  ) { }

  public deeplinkRouting(data, back_default = null): void {
    this.router.navigateByUrl('app')
    return;
    let dataCopy = data;
    if (!dataCopy) {
      dataCopy = window.localStorage.getItem('deeplink');
      if (dataCopy) {
        window.localStorage.removeItem('deeplink');
        dataCopy = JSON.parse(dataCopy);
        console.log('retrieved data ', dataCopy);
      }
    }

    if (dataCopy) {
      window.localStorage.setItem('auth_override', '1');
      if (dataCopy.gid && dataCopy.player_id) {
        if (dataCopy.vid) {
          console.log(' Go to Player Video');
          // Go to $state.go('pa.player-game-video', {id:data.player_id, gid:data.gid.replace(/ /g,"+"),vid:data.vid.replace(/ /g,"+")}, {reload:true});
          return;
        } else {
          console.log(' Go to Player Game!');
          // Go to $state.go('pa.player-game-page', {id:data.player_id, gid:data.gid.replace(/ /g,"+")}, {reload:true});
          return;
        }
      } else if (dataCopy.player_id) {
        console.log(' Go to Player Page!');
        // Go to $state.go('pa.player-page', {id:data.player_id}, {reload:true});
        return;
      } else if (dataCopy.team_id) {
        console.log(' Go to Team Page');
        // Go to $state.go('pa.team-page', {id:data.team_id}, {reload:true});
        return;
      }
    }

    if (!back_default) {
      const profile: ProfileData = JSON.parse(window.localStorage.getItem('profile'));
      const team = profile.team;
      const facility = profile.facility;
      const player = profile.facility;
      if (team || facility) {
        // Go to schedule
        this.router.navigateByUrl('app');
      } else if (player) {
        this.router.navigateByUrl('home');
      } else {
        this.router.navigateByUrl('following');
      }
    } else {
      // Go to Back
      this.location.back();
    }
  }

  public checkAndGo(payload) {
    

    if (payload.pa_type && payload.pa_type == 'event') {
      if (payload.foreground) {
        this.alertCtrl.create({
          header: 'New Event Notification!',
          message: payload.pa_event.name,
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              handler: (blah) => {
                console.log('Confirm Cancel: blah');
              }
            }, {
              text: 'Go to Alerts',
              handler: () => {
                this.leagueService.getTournaments().then((tournaments: TournamentsData) => {
                  let tournamentData = null;
                  tournaments.events.forEach(element => {
                    if (element.pk == payload.pa_event.pk) {
                      const navigationExtras: NavigationExtras = {
                        state: {
                          tournamentId: element.pk,
                          tournament: element
                        }
                      }
                      this.router.navigateByUrl('app/tournaments/notifications', navigationExtras)
                    }
                  });
                })
              }
            }
          ]
        }).then((alertModal) =>{
          alertModal.present();
        })
      } else {
        console.log('Event Notification Payload === ', payload, JSON.stringify(payload));
        this.leagueService.getTournaments().then((tournaments: TournamentsData) => {
          let tournamentData = null;
          tournaments.events.forEach(element => {
            if (element.pk == payload.pa_event.pk) {
              const navigationExtras: NavigationExtras = {
                state: {
                  tournament: element
                }
              }
              this.router.navigateByUrl('app/tournaments/tournament-schedule', navigationExtras)
            }
          });
        })
      }
    } else if (payload.pa_type && payload.pa_type == 'game') {
      if (payload.foreground) {
        this.alertCtrl.create({
          header: 'New Game Notification!',
          message: payload.pa_event.name,
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              handler: (blah) => {
                console.log('Confirm Cancel: blah');
              }
            }, {
              text: 'Go to Game',
              handler: () => {
                const navigationExtras: NavigationExtras = {
                  state: {
                    gameId: payload.game_id
                  }
                }
                this.router.navigateByUrl('game', navigationExtras)
              }
            }
          ]
        }).then((alertModal) =>{
          alertModal.present();
        })
      } else {
        console.log('Event Notification Payload === ', payload, JSON.stringify(payload));        
        const navigationExtras: NavigationExtras = {
          state: {
            tournament: payload.game_id
          }
        }
        this.router.navigateByUrl('game', navigationExtras)
      }
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpParameterCodec } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ErrorFormat, BaseService } from './base.service';
import { PlayerData } from './user.service';
import { LOCAL_CACHE_DISABLE } from '../common/constant';

export interface FollowingDetailData {
  count: number;
  items: Array<PlayerData>;
}

export interface FollowingResultData {
  detail: FollowingDetailData;
}

export interface PlayerCrossData {
  avatar_url: string | null;
  grad_year: string | number;
  jersey: number;
  name: string;
  pk: number;
  position: string;
  me_following: boolean;
  team_name: string;
  pts_id: string;
  card_id?: string;
  id?: number
}

export interface PlayerCrossResult {
  cross: Array<PlayerCrossData>;
}

export interface PlayerPlayData {
  charts?: any;
  dash: Array<any>;
  myschedule?: any;
  rabbit?: Array<any>;
  schedule?: any;
  scout?: Array<any>;
}

export interface MainGameData {
  hitting: PlayerPlayData;
  pitching: PlayerPlayData;
}

export interface PlayerSeasonData {
  from_where: any | null;
  is_pitcher_first: boolean;
  jersey_number: number;
  pk: number;
  position: string | null | number;
  scouting_url: string;
  season_name: string;
  season_year: number | null;
  team_id: number;
  team_logo: string;
  team_name: string;
}

export interface PlayerSelfData {
  avatar_url: string | null;
  first_name: string;
  followers: number;
  full_name: string;
  has_stasowner: boolean;
  in_circle: boolean;
  is_hitter: boolean;
  is_pitcher: boolean;
  is_private: boolean;
  its_me: boolean;
  last_name: string;
  me_following: boolean;
  pk: number;
  requested_access: boolean;
  scout: boolean;
  seasons: Array<PlayerSeasonData>;
  video_playlist_key: string;
}

export interface PlayerHomeResult {
  cross: Array<PlayerCrossData>;
  game_data: MainGameData;
  is_pitcher: boolean;
  player: PlayerSelfData;
  season: number;
}

export class CustomHttpParamEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }
  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }
  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

@Injectable({
  providedIn: 'root'
})
export class PlayerService {

  constructor(
    private http: HttpClient,
    private baseService: BaseService
  ) { }

  public unFollowPlayer(playerPK): Promise<FollowingResultData | ErrorFormat> {
    const url = this.baseService.apiDomain + '/api/unfollow/player/' + playerPK;
    return this.http.get<FollowingResultData>(url).toPromise().then((result) => {
      console.log('UnFollow Player Result ==== ', result);
      return result;
    }).catch(this.baseService.handleError);
  }

  public followPlayer(playerPK): Promise<FollowingResultData | ErrorFormat> {
    const url = this.baseService.apiDomain + '/api/follow/player/' + playerPK;
    return this.http.get<FollowingResultData>(url).toPromise().then((result) => {
      console.log('UnFollow Player Result ==== ', result);
      return result;
    }).catch(this.baseService.handleError);
  }

  public getPlayerData(playerPK, params, headers = null, disableCache = false): Promise<any> {
    const url = this.baseService.apiDomain + '/api/player/' + playerPK;
    let urlHeaders = new HttpHeaders();

    if (disableCache) {
      urlHeaders = urlHeaders.set(LOCAL_CACHE_DISABLE, '1');
    }

    if (params != null) {
      let reqParams = new HttpParams();
      Object.keys(params).forEach(key => {
        reqParams = reqParams.set(key, params[key]);
      });

      if (headers) {
        reqParams = new HttpParams({encoder: new CustomHttpParamEncoder()});
        Object.keys(params).forEach(key => {
          reqParams = reqParams.set(key, params[key]);
        });
        urlHeaders = urlHeaders.set('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8');
        return this.http.get<any>(url, {
          params: reqParams,
          headers: urlHeaders
        }).toPromise().then((result) => {
          return result;
        });
      } else {
        return this.http.get<any>(url, {
          params: reqParams,
          headers: urlHeaders
        }).toPromise().then((result) => {
          return result;
        });
      }
    } else {
      return this.http.get<any>(url, {headers: urlHeaders}).toPromise().then((result) => {
        return result;
      });
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of, from, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Storage } from '@ionic/storage';
import { RequestCachingService } from './request-caching.service';
import { LOCAL_CACHE_DISABLE } from '../../common/constant';
import { BaseService } from '../base.service';


@Injectable({
  providedIn: 'root'
})
export class CachingInterceptorService {

  constructor(
    private cachingService: RequestCachingService,
    private baseService: BaseService,
    private storage: Storage
  ) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('auth') || req.url.includes('signup') || req.url.includes('reset_password') || req.url.includes('/o/token')) {
      return next.handle(req);
    }
    if (req.url.includes('json')) {
      const request = req.clone({
        setHeaders: {
          'Cache-Control' : 'private, s-maxage=0, max-age=0, max-age=0, must-revalidate'
        }
      });
      return next.handle(request);
    }
    return from(this.storage.get('access_token'))
    .pipe(
      switchMap(token => {
        console.log('Access Token === ', token);
        let request = req.clone();
        
        if (token && !req.url.includes('json')) {
          if (!req.url.includes('apns') && !req.url.includes('gcm')) {
            const tempVenue = req.params.get('venue_id');
            if (tempVenue && tempVenue !== '') {
              request = req.clone({
                setHeaders: {
                  Authorization : `Bearer ${token}`
                },
                setParams: {
                  venue_id: tempVenue
                }
              });
            } else {
              request = req.clone({
                setHeaders: {
                  Authorization : `Bearer ${token}`
                },
                setParams: {
                  [this.baseService.currentAppType.area]: this.baseService.currentAppType.value.toString()
                }
              });
            }
          } else {
            request = req.clone({
              setHeaders: {
                Authorization : `Bearer ${token}`
              }
            });
          }
        }

        const cachedResponse = this.cachingService.get(request);
        if (cachedResponse && environment.cacheEnable && !request.headers.has(LOCAL_CACHE_DISABLE)) {
          return of(cachedResponse);
        }

        if (request.headers.has(LOCAL_CACHE_DISABLE)) {
          const newHeaders = request.headers.delete(LOCAL_CACHE_DISABLE);
          request = request.clone({
            headers: newHeaders
          });
        }

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // do nothing for now
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                const status =  error.status;
                const reason = error && error.error.reason ? error.error.reason : '';

                // this.presentAlert(status, reason);
                return throwError(error);
            })
        );
      })
    )

  //   const access_token: any = window.localStorage.getItem('access_token');
  //   let request = req.clone();
  //   if (access_token && !req.url.includes('json')) {
  //     console.log('Access Token === ', access_token);
  //     request = req.clone({
  //       setHeaders: {
  //         Authorization : `Bearer ${access_token}`
  //       }
  //     });
  //   }

  //   const cachedResponse = this.cachingService.get(request);
  //   if (cachedResponse && environment.cacheEnable && !request.headers.has(LOCAL_CACHE_DISABLE)) {
  //    return of(cachedResponse);
  //  }
  //   if (request.headers.has(LOCAL_CACHE_DISABLE)) {
  //    const newHeaders = request.headers.delete(LOCAL_CACHE_DISABLE);
  //    request = request.clone({
  //      headers: newHeaders
  //    });
  //  }
  //   return this.sendRequest(request, next, this.cachingService);
  }

  private cachingTimePeriod(request: HttpRequest<any>): number | undefined {
    if (environment.cacheEnable == true && request.url.indexOf('/token') == -1 &&
    request.url.indexOf('/logout') == -1 && request.url.indexOf('/reset_password') == -1) {
      return environment.cacheTime;
    }
    return undefined;
  }

  public sendRequest(req: HttpRequest<any>, next: HttpHandler, cache: RequestCachingService): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap(event => {
      if (event instanceof HttpResponse) {
        const cachedTime = this.cachingTimePeriod(req);
        if (event.ok && cachedTime !== undefined) {
          console.log('Send Request Event === ', event.ok, cachedTime);
          cache.put(req, event.clone(), cachedTime, req.params);
        }
      }
    }));
  }
}

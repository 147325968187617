import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeChange'
})
export class TimeChangePipe implements PipeTransform {

  transform(value: any, style: string = 'normal', ...args: any[]): any {

    const weekArray = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const MonthArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    const tDateObj = style === 'schedule' || style === 'defaultdate' || style === 'gschedule' ? new Date(value * 1000) : new Date(value);
    let hours = tDateObj.getHours();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours > 12 ? hours - 12 : hours;
    const month = tDateObj.getMonth() + 1;
    const date = tDateObj.getDate();
    const resultDate = date < 10 ? '0' + date : date;
    const minute = tDateObj.getMinutes() > 10 ? tDateObj.getMinutes() : '0' + tDateObj.getMinutes();
    if (style === 'normal' || style === 'gschedule') {
      return hours + ':' + minute + ' ' + ampm + ' ' + month + '/' + resultDate;
    } else if (style === 'schedule') {
      return hours + ':' + minute + ' ' + ampm;
    } else if (style === 'defaultdate') {
      const weekDay = tDateObj.getDay()
      return weekArray[weekDay] + ' ' + MonthArray[tDateObj.getMonth()] + ' ' + tDateObj.getDate();
    } else if (style === 'events') {
      const darray = value.split('-');
      if (!darray || darray.length < 2) return ''
      const monthVal = parseInt(darray[1]) - 1
      return MonthArray[monthVal] + ' ' + darray[2]
    } else {
      return month + '/' + date;
    }
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SelectItemComponent } from './select-item.component';
import { TimeCheckModule } from '../../pipes/time-check.module';

@NgModule({
  imports: [ CommonModule, FormsModule, IonicModule, TimeCheckModule],
  declarations: [SelectItemComponent],
  exports: [SelectItemComponent]
})
export class SelectItemComponentModule {}
